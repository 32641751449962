import React from 'react';
import Slider from "react-slick";

import './style.css';



const AdsCarousel = (props) => {

    var settings = {
            dots: false,
            infinite: props.ads.length >= 3,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: "linear",
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
      };

    return(        
            <Slider {...settings}>
                  {
                    props.ads && props.ads.map((ad) => (
                        <div key={ad.id} className="car-card">
                            <img src={ad.image} alt="" id="car-img"/>
                        </div>
                    ))
                  }
            </Slider>
    );
}

export default AdsCarousel;