import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

import { fetchLocation } from '../redux/location/actions';
import { getFilteredServices } from '../redux/filters/actions';
import { fetchLocalAds } from '../redux/ads/actions';
import ZoneBanner from '../components/home/ZoneBanner';
import ServiceCard from '../components/home/ServiceCard';
import AdsCarousel from '../components/ads/AdsCarousel'
import CircularProgress from '@mui/material/CircularProgress';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Services = ({data}) => {
	const theme = useTheme();
  	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid container spacing={isSmall ? 1 : 6}>
			{
				data && data.results && data.results.map((service) => (
					<Grid item xs={6} sm={6} md={4} lg={3} key={service.id}>
						<ServiceCard service={service}/>
					</Grid>
				))
			}
		</Grid>
	);
} 

const SearchZone = () => {
	const {location, id} = useParams();
	const dispatch = useDispatch();
	const [query, setQuery] = useState(null);
	const [page, setPage] = useState(1);
	const [isCateg, setIsCateg] = useState(null);

	let navigate = useNavigate();

	const { isLoading, loc_detail,
		    filterLoading, data, ads, adLoading} = useSelector(state => {
        return {
            isLoading: state.location.isLoading,
            loc_detail: state.location.location,
            filterLoading: state.filters.isLoading,
            data: state.filters.filteredServices,
            ads: state.ad.localAds,
            adLoading: state.ad.isLoading
        };
    });

    // called on opening page (search field null)
	React.useEffect(() => {
		if (query === null) {
			dispatch(getFilteredServices('', location, 'no', page));
		}
		// eslint-disable-next-line
	}, []);

	// Called When search field cleaned
	React.useEffect(() => {
		if (query === '') {
			setPage(1);
			dispatch(getFilteredServices('', location, 'no', page));
		}
		// eslint-disable-next-line
	}, [query]);

	// To Fetch location details and ads
	React.useEffect(() => {
		if( !isNaN(Number(id)) ) {
			dispatch(fetchLocalAds(id));
			dispatch(fetchLocation(id));
		} else {
			navigate('/');
		}
		// eslint-disable-next-line
	}, [id, location]);

	// Called when user select the suggestion from dropdown
	const setFilterValue = (value, categ) => {
		setPage(1);
		setQuery(value);
		setIsCateg(categ);
		dispatch(getFilteredServices(value, loc_detail.name, categ, 1));
	}

	// called when typing search field
	const handleSearchChange = (value) => {
		setQuery(value);
	}

	const handlePageChange = (event, value) => {
        setPage(value);
        let categ = isCateg === null ? 'no' : isCateg;
        let cQuery = query === null ? '' : query;
		dispatch(getFilteredServices(cQuery, loc_detail.name, categ, value));
    };

    const loader = () => {
		return (
			<div style={{
				width: "100%", 
				height: "100vh", 
				textAlign: "center",
				marginTop: "80px"
			}}>
				<CircularProgress />
			</div>
		);
	}

	return(
		<>
			{isLoading === true ? (loader()) : (
				<React.Fragment>
					<ZoneBanner
						location={loc_detail}
						setFilterValue={setFilterValue}
						handleSearchChange={handleSearchChange}
					/>
				
					{adLoading === true ? (
						<div style={{
							width: "100%",
							textAlign: "center"
						}}>
							<CircularProgress />
						</div>) : (
						<div style={{marginTop: "80px"}}>
				        	<AdsCarousel ads={ads}/>
						</div>)
					}

					{filterLoading === true ? (
							<div style={{
								width: "100%",
								height: "300px",
								textAlign: "center",
								marginTop: "100px"
							}}>
								<CircularProgress />
							</div>
						) : (
							(data && data.results && data.results.length > 0) ? (
								<div style={{padding: "35px", marginTop: "40px"}}>
									<p>Shops and services</p>
									<Services data={data}/>
									<div style={{marginTop: "30px", width: "100%", display: 'flex', justifyContent: 'center'}}>
					                	<Pagination count={(data && data.total) || 0} page={page} onChange={handlePageChange} />
					            	</div>		
								</div>) : (
								<div style={{width: "100%", height: "400px", textAlign: "center", marginTop: "100px"}}>
									<p> No Data</p>
								</div>
							)
						)
					}
				</React.Fragment>)
			}
		</>
	);
}

export default SearchZone;
