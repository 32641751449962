
const nameValidation = (fields, errors, formIsValid) => {
    if(!fields["name"]){
        formIsValid = false;
        errors["name"] = "Name Cannot be Empty";
    }

    else if(fields["name"] !== null && fields["name"].length < 3){
        formIsValid = false;
        errors["name"] = "Atleast 3 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const addressValidation = (fields, errors, formIsValid) => {
    if(!fields["address"]){
        formIsValid = false;
        errors["address"] = "Address Cannot be Empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const mobileValidation = (fields, errors, formIsValid) => {
    if(!fields["mobile"]){
        formIsValid = false;
        errors["mobile"] = "Mobile number cannot be empty";
    }

    else if(typeof fields["mobile"] !== "undefined" && fields["mobile"] !== null){
        if(!fields["mobile"].match(/^\d{10}$/)){
            formIsValid = false;
            errors["mobile"] = "Invalid mobile number";
        }        
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const passwordValidation = (fields, errors, formIsValid) => {
    if(!fields["password"]){
       formIsValid = false;
       errors["password"] = "Password cannot be empty";
    }

    else if(fields["password"] !== null && fields["password"].length < 6){
        formIsValid = false;
        errors["password"] = "Atleast 6 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const password1Validation = (fields, errors, formIsValid) => {
    if(!fields["password1"]){
       formIsValid = false;
       errors["password1"] = "Password cannot be empty";
    }

    else if(fields["password1"] !== null && fields["password1"].length < 8){
        formIsValid = false;
        errors["password1"] = "Atleast 8 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const password2Validation = (fields, errors, formIsValid) => {
    if(!fields["password2"]){
       formIsValid = false;
       errors["password2"] = "Password cannot be empty";
    }

    else if(fields["password2"] !== null && fields["password2"].length < 8){
        formIsValid = false;
        errors["password2"] = "Atleast 8 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const authPasswordValidation = (fields, errors, formIsValid) => {
    if(!fields["password"]){
       formIsValid = false;
       errors["password"] = "Password cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const oldPasswordValidation = (fields, errors, formIsValid) => {
    if(!fields["old_password"]){
       formIsValid = false;
       errors["old_password"] = "Password cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const newPasswordValidation = (fields, errors, formIsValid) => {
    if(!fields["new_password"]){
       formIsValid = false;
       errors["new_password"] = "Password cannot be empty";
    }

    else if(fields["new_password"] !== null && fields["new_password"].length < 8){
        formIsValid = false;
        errors["new_password"] = "Atleast 8 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const emailValidation = (fields, errors, formIsValid) => {
    if(!fields["email"]){
       formIsValid = false;
       errors["email"] = "Email Cannot be empty";
    }

    else if(typeof fields["email"] !== "undefined"){
        if(!fields["email"].match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            formIsValid = false;
            errors["email"] = "Invalid email";
        }        
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const locationValidation = (fields, errors, formIsValid) => {
    if(!fields["location"]){
       formIsValid = false;
       errors["location"] = "Location cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const categoryValidation = (fields, errors, formIsValid) => {
    if(!fields["category"]){
       formIsValid = false;
       errors["category"] = "Category cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const adminValidation = (fields, errors, formIsValid) => {
    if(!fields["admin"]){
       formIsValid = false;
       errors["admin"] = "Admin cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const imageValidation = (fields, errors, formIsValid) => {
    if(!fields["image"]){
       formIsValid = false;
       errors["image"] = "Image cannot be empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const contactValidation = (fields, errors, formIsValid) => {
    if(!fields["contact"]){
       formIsValid = false;
       errors["contact"] = "Contact number cannot be empty";
    }

    else if(fields["contact"] !== null && fields["contact"].length < 6){
        formIsValid = false;
        errors["contact"] = "Atleast 6 characters required";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

const expiryValidation = (fields, errors, formIsValid) => {
    if(!fields["expiry"]){
        formIsValid = false;
        errors["expiry"] = "Date Cannot be Empty";
    }

    var obj = {
        errors: errors,
        formIsValid: formIsValid
    };

    return obj;
}

module.exports = {
    nameValidation,
    addressValidation,
    mobileValidation,
    passwordValidation,
    password1Validation,
    password2Validation,
    emailValidation,
    locationValidation,
    categoryValidation,
    adminValidation,
    imageValidation,
    contactValidation,
    expiryValidation,
    authPasswordValidation,
    oldPasswordValidation,
    newPasswordValidation
}