import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import ImagePicker from './ImagePicker';
import { useDispatch, useSelector } from 'react-redux';
import { createLocation, updateLocation, locationClean } from '../../redux/location/actions';
import './style.css';
import CircularProgress from '@mui/material/CircularProgress';

import {
    nameValidation,
    imageValidation
} from '../../utils/validation';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const LocationForm = (props) => {
    const dispatch = useDispatch();
    const [loc, setLoc] = React.useState({id: null, name: null, image: null});
    const [errorText, setErrorText] = React.useState({name: '', image: ''});

    const [blobImg, setBlobImg] = React.useState(null);

    const { isLoading } = useSelector(state => {
        return {
            isLoading: state.location.isLoading
        };
    });

    React.useEffect(() => {
        if (props.location !== {}) {
            setErrorText({...errorText, name: '', image: ''});
            setLoc(props.location);
            setBlobImg(null);
        }
        // eslint-disable-next-line
    }, [props.location]);

    React.useEffect(() => {
        if (props.newe === true){
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.newe]);

    React.useEffect(() => {
        if (props.open === false){
            setLoc({...loc, id: null, name: '', image: null});
            setErrorText({...errorText, name: '', image: ''});
            dispatch(locationClean());
        }
        // eslint-disable-next-line
    },[props.open]);

    const handleChange = (e) => {
        setLoc({...loc, name: e.target.value});
    }

    const submitForm = () => {
        if (validateForm()){
            let fd = appendFormData();
            if (loc.id === null)
                dispatch(createLocation(fd));
            else
                dispatch(updateLocation(loc.id, fd));
        }
    }


    const validateForm = () => {
        let fields = loc;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = nameValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = imageValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const setImage = (imageBlob) => {
        setLoc({...loc, image: "set"});
        setBlobImg(imageBlob);
    }

    const appendFormData = () => {
        let formData = new FormData();
        formData.append("name", loc.name);
        
        if(blobImg !== null)
            formData.append("image", blobImg);
        return formData;
    }

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Add Location
                </Typography>

                <InputLabel htmlFor="outlined-adornment-password">Location</InputLabel>
                <TextField
                    id="outlined-basic"
                    name="name"
                    size='small'
                    fullWidth
                    variant="outlined"
                    style={{marginBottom: 10}}
                    onChange={handleChange}
                    value={loc.name}
                    error={errorText.name}
                    helperText={errorText.name}
                />

                <InputLabel htmlFor="image">Image</InputLabel>
                <ImagePicker setImage={setImage} api_image={loc.image} widths={1200} heights={700}/>
                <small style={{color: "red"}}>{errorText.image}</small><p></p>
                <Button 
                    onClick={submitForm} 
                    className='button'
                    disabled={isLoading}
                    startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                >
                    Submit
                </Button>
            </Box>
        </Modal>    
    );
}

export default LocationForm;