import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import { userLogin } from '../../redux/auth/actions';
import './style.css';
import CircularProgress from '@mui/material/CircularProgress';

import {
    emailValidation,
    authPasswordValidation
} from '../../utils/validation';

const SignIn = () => {
    const dispatch = useDispatch();
    const [errorText, setErrorText] = React.useState({email: '', password: ''});

    const { isLoading } = useSelector(state => {
        return { isLoading: state.auth.isLoading };
    });

    const [values, setValues] = React.useState({
        email: '',
        password: ''
    });
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitForm = () => {
        if (validateForm()){
            dispatch(userLogin(values));
        }
    }

    const validateForm = () => {
        let fields = values;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = emailValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = authPasswordValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    return(
        <Box>
            <Typography variant='h5' style={{color: 'grey'}}>Sign In</Typography><br/>
            <InputLabel htmlFor="outlined-adornment-password">E-mail</InputLabel>
            <TextField
                id="outlined-basic"
                size='small'
                style={{marginBottom: 10}}
                fullWidth
                variant="outlined"
                error={errorText.email}
                helperText={errorText.email}
                onChange={handleChange('email')}
            />
            <br/>

            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                variant="outlined"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                style={{marginBottom: 4}}
                fullWidth
                error={errorText.password}
                helperText={errorText.password}
                onChange={handleChange('password')}
                size='small'
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
            /><br/>

            <Box className="link">
                <Link to="/auth/forgotpassword" style={{color: "#45b3e0", textDecoration: "none"}}>
                    <small>Forgot password ?</small>
                </Link>
            </Box>
                    
            <Button className="button" onClick={submitForm} 
                disabled={isLoading}
                startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}>
                SignIn
            </Button>
        </Box>      
    );
}

export default SignIn;
