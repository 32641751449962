import * as actions from './actions';

const initState = {
	suggestions: {},
	isLoading: false,
    sugLoading: false,
	error: null,
    filteredServices: null,
    home: {
        ads: [],
        recent_services: [],
        recent_locations: []
    }
}

const filterReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SUGGESTIONS_START:
            return {
            	...state,
            	sugLoading: true
            };
        case actions.SUGGESTIONS_SUCCESS:
            return {
            	...state,
            	sugLoading: false,
            	suggestions: action.data,
            };
        case actions.SUGGESTIONS_FAIL:
            return {
            	...state,
            	sugLoading: false,
            	error: action.error
            };
        case actions.FILTERING_START:
            return {
                ...state,
                isLoading: true
            };
        case actions.FILTERING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filteredServices: action.data,
            };
        case actions.FILTERING_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case actions.HOME_START:
            return {
                ...state,
                isLoading: true
            };
        case actions.HOME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                home: action.data,
            };
        case actions.HOME_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}

export default filterReducer;