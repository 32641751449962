import React from 'react';
import PageHeader from '../../components/admin/PageHeader';
import { makeStyles } from '@mui/styles';
import { Paper, TextField, Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { DataGrid } from '@mui/x-data-grid';
import ServiceForm from '../../components/forms/ServiceForm';
import ConfirmDialog from '../../components/forms/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices, deleteService, getService } from '../../redux/service/actions';
import { fetchCategories } from '../../redux/category/actions';
import { fetchAdmins } from '../../redux/admin/actions';
import { capitalize } from  '../../utils/capitalize';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: '10px',
        padding: '10px',
        "@media (min-width: 1280px)": {
          margin: "0px"        
      },
    },
    searchInput: {
        width: '75%'
    }
}));

const Shop = () => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [newe, setNew] = React.useState(false); 
    const handleClose = () => setOpen(false);
    const [dialog, setDialog] = React.useState(false);
    const [delId, setDelId] = React.useState();

    // Backend pagination states
    const [rowCount, setRowCount] = React.useState(undefined);
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');

    const [rowsState, setRowsState] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const [user, setUser] = React.useState({
        user_id: null,
        user_role: ''
    });

    const { loading, services, service, success } = useSelector(state => {
        return {
            loading: state.service.isLoading,
            services: state.service.services,
            service: state.service.service,
            success: state.service.success
        };
    });

    const { categories } = useSelector(state => {
        return { categories: state.category.categories };
    });

    const { admins } = useSelector(state => {
        return { admins: state.admin.admins };
    });

    React.useEffect(() => {
        setRowCount(services.count);
        setData(services.results ?? []);
        // eslint-disable-next-line
    }, [services]);

    const [rowCountState, setRowCountState] = React.useState(rowCount || 0);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
          rowCount !== undefined ? rowCount : prevRowCountState,
        );
        // eslint-disable-next-line
      }, [rowCount, setRowCountState]);

    const handlePageChange = (page) => {
        setRowsState((prev) => ({ ...prev, page }));
        dispatch(fetchServices(search, page+1));
    }

    React.useEffect(() => {
        dispatch(fetchServices(search, 1));
        getUser();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (open === true){
            dispatch(fetchCategories(''));
            if (user.user_role === 'super_user')
                dispatch(fetchAdmins(''));
        }// eslint-disable-next-line
    }, [open]);

    React.useEffect(() => {
        if(success) handleClose();
        // eslint-disable-next-line
    }, [success]);

    const handleOpen = () => {
        setNew(true);
        setOpen(true);
    }

    const getUser =() => {
        setUser({...user,
            user_id: localStorage.getItem('user_id'),
            user_role: localStorage.getItem('user_role')
        })
    }

    const getDetail = (id) => {
        setOpen(true);
        setNew(false);
        dispatch(getService(id));
    }   

    const handleSearch = (e) => {
        setSearch(e.target.value);
        dispatch(fetchServices(e.target.value, 1));
        setRowsState({ ...rowsState, page: 0 });
    }

    const handleDelete = () => {
        let cPage = rowsState.page + 1;
        
        if (services.current_page === services.total && services.results.length === 1 && services.total !== 1)
            cPage = cPage - 1;
        else if(services.results.length === 1)
            cPage = 1;
            
        dispatch(deleteService(delId, search, cPage))
        setDialog(false);
    }

    const openDialog = (id) => {
        setDelId(id);
        setDialog(true);
    }

    const closeDialog = () => {
        setDialog(false);
    }

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name', 
            sortable: false, 
            flex:1, 
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { 
            field: 'category', 
            headerName: 'Category', 
            sortable: false , 
            flex:1, 
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { 
            field: 'location', 
            headerName: 'Location', 
            sortable: false , 
            flex:1, 
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { 
            field: 'admin', 
            headerName: 'Admin', 
            sortable: false, 
            flex:1, 
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { field: 'contact', headerName: 'Contact', sortable: false, flex:1, minWidth: 150,},
        { 
            field: 'actions', 
            headerName: 'Actions', 
            align: "right",
            sortable: false,
            flex:1,
            minWidth: 100,
            renderCell: (cellValues) => {
            return (
                <>
                    <IconButton color="primary" aria-label="upload picture" onClick={() => getDetail(cellValues.id)} component="span">
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="error" aria-label="upload picture" onClick={() => openDialog(cellValues.id)} component="span">
                        <DeleteIcon/>
                    </IconButton>
                </>
            );
          }
        }
    ]


    return (
        <div id="datatable">
           <PageHeader
                title="Services"
                subTitle="Add, Edit Delete Services"
                icon={<StoreMallDirectoryIcon fontSize="large" />}
            />

            <Paper className={classes.pageContent}>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            name="search"
                            size="small"
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row-reverse">      
                            <Button variant="outlined" size="large" onClick={handleOpen}>Add New</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        rowCount={rowCountState}
                        rowsPerPageOptions={[10]}
                        loading={loading}
                        pagination
                        {...rowsState}
                        paginationMode="server"

                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        extendRowFullWidth
                        autoHeight={true}

                        onPageChange={handlePageChange}
                    />
                </div>
            </Paper>
            <ServiceForm
                open={open}
                newe={newe}
                service={service}
                admins={admins}
                search={search}
                categories={categories}
                rowsState={rowsState}
                rowCountState={rowCountState}
                setRowsState={setRowsState}
                handleClose={handleClose}
            /> 

            <ConfirmDialog
                open={dialog}
                handleClose={closeDialog}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default Shop;