import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ImagePicker from './ImagePicker';
import { useDispatch, useSelector } from 'react-redux';
import { createAdmin, updateAdmin } from '../../redux/admin/actions';
import './style.css';
import CircularProgress from '@mui/material/CircularProgress';

import {
    nameValidation,
    emailValidation,
    mobileValidation,
    passwordValidation,
    locationValidation
} from '../../utils/validation';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const AdminForm = (props) => {

    const tempValue = {
        id: null,
        name: 'select',
        image: ''
    };
    const dispatch = useDispatch();
    const [blobImg, setBlobImg] = React.useState(null);

    const { isLoading } = useSelector(data => {
        return {
            isLoading: data.admin.isLoading
        };
    });

    const [visible, setVisible] = React.useState({});
    const [state, setState] = React.useState({
        id : null, 
        name: null,
        email: null,
        mobile: null,
        password: null,
        profile_pic: null,
        location: null,
        is_admin: true
    });

    const [errorText, setErrorText] = React.useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        profile_pic: '',
        location: ''
    });

    React.useEffect(() => {
        if (props.admin !== {}){
            setState({...state,
                id : props.admin.id, 
                name: props.admin.name,
                email: props.admin.email,
                mobile: props.admin.mobile,
                profile_pic: props.admin.profile_pic,
                location: props.admin.location,
                is_admin: true
            });
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.admin]);

    React.useEffect(() => {
        if (props.newe === true){
            resetForm();
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.newe]);

    React.useEffect(() => {
        if (props.open === false){
            resetForm();
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.open]);

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const handleSelect = (e) => {
        setState({...state, location: e});
    }

    const handleClickShowPassword = () => {
        setVisible({
          ...visible,
          showPassword: !visible.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setImage = (imageBlob) => {
        setBlobImg(imageBlob);
    }

    const submitForm = () => {
        if (validateForm()){
            let fd = appendFormData();
            if (state.id === null || state.id === undefined)
                dispatch(createAdmin(fd));
            else
                dispatch(updateAdmin(state.id, fd));
        }
    }

    const validateForm = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = nameValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = mobileValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        if (state.id === null) {
            validationDetails = emailValidation(fields, errors, formIsValid);
            formIsValid = setValidationFlag(validationDetails);
            validationDetails = passwordValidation(fields, errors, formIsValid);
            formIsValid = setValidationFlag(validationDetails);
        }
        validationDetails = locationValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const appendFormData = () => {
        let formData = new FormData();

        formData.append("name", state.name);

        formData.append("email", state.email);

        formData.append("mobile", state.mobile);

        formData.append("password", state.password);

        formData.append("is_admin", true);

        if(state.location !== null && !isNaN(Number(state.location.id)))
            formData.append("location", state.location.id);
        
        if(blobImg !== null)
            formData.append("profile_pic", blobImg);
        
        return formData;
    }

    const resetForm = () => {
        setState({...state,
            id : null, 
            name: null,
            email: null,
            mobile: null,
            password: null,
            profile_pic: null,
            location: null,
            is_admin: true
        })
        setErrorText({...errorText,
            name: '',
            email: '',
            mobile: '',
            password: '',
            profile_pic: '',
            location: '',
        });
    }

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflow:'scroll'}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Add Admin
                </Typography>

                <InputLabel htmlFor="name">Admin Name</InputLabel>
                <TextField
                    id="admin-name"
                    size='small'
                    name="name"
                    fullWidth
                    variant="outlined"
                    value={state.name}
                    error={errorText.name}
                    helperText={errorText.name}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />

                <InputLabel htmlFor="email">Email</InputLabel>
                <TextField
                    id="email"
                    size='small'
                    name="email"
                    fullWidth
                    variant="outlined"
                    disabled={state.id === null || state.id === undefined ? false : true}
                    value={state.email}
                    error={errorText.email}
                    helperText={errorText.email}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />

                <InputLabel htmlFor="mobile">Mobile Number</InputLabel>
                <TextField
                    id="mobile"
                    className='input'
                    size='small'
                    type='number'
                    name="mobile"
                    fullWidth
                    variant="outlined"
                    value={state.mobile}
                    error={errorText.mobile}
                    helperText={errorText.mobile}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />
                
                {state.id === null || state.id === undefined ?
                <div>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <TextField
                        id="password"
                        type={visible.showPassword ? 'text' : 'password'}
                        name='password'
                        style={{marginBottom: 10}}
                        fullWidth
                        onChange={handleChange}
                        size='small'
                        error={errorText.password}
                        helperText={errorText.password}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                    {visible.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </div> 
                : null }

                <InputLabel htmlFor="location">Location</InputLabel>
                <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    size='small'
                    style={{marginBottom: 10}}
                    options={props.locations}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={(state.location) ?? tempValue }
                    onChange={(event, newValue) => handleSelect(newValue)}
                    renderInput={(params) => <TextField 
                        variant="outlined" 
                        {...params} 
                        error={errorText.location}
                        helperText={errorText.location}
                        />}
                />

                <ImagePicker setImage={setImage} api_image={state.profile_pic} widths={128} heights={128}/>
                <small style={{color: "red"}}>{errorText.image}</small><p></p>
                <Button 
                    onClick={submitForm} className='button'
                    disabled={isLoading}
                    startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                >
                    Submit
                </Button>
            </Box>
        </Modal>        
    );
}

export default AdminForm;