import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
    root: {
        borderRadius: '15px !important',
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important"
    },
});

const CountCard = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            {props.title}
                        </Typography>
                        <div style={{height: "10px"}}></div>
                        {props.loading ? (
                                <Typography component="div" variant="h5">
                                    ...
                                </Typography>
                            ) : (
                            <Typography component="div" variant="h5">
                                {props.count}
                            </Typography>)
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default CountCard;