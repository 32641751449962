import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { capitalize } from  '../../utils/capitalize';

const useStyles = makeStyles({
    root: {
        borderRadius: "15px !important",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important",
        marginBottom: "30px !important",
        minHeight: "270px !important",
        height: "90% !important",
        width: "100% !important",
        objectFit: "fill !important"
    },
    media: {
    	objectFit: 'fill !important'
    }
});

const ServiceCard = (props) => {
	const classes = useStyles();

	return(
		<Card className={classes.root}>
			<CardMedia
				className={classes.media}
				component="img"
				alt=""
				height="170"
				image={props.service.image}
			/>
			<CardContent style={{textAlign: "center"}}>
				<Typography gutterBottom variant="body1" component="div">
					{capitalize(props.service.name)}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{props.service.address}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{props.service.contact}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default ServiceCard;