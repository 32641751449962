import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const CATEGORIES_FETCH_START = 'CATEGORIES_FETCH_START';
export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS';
export const CATEGORIES_FETCH_FAIL = 'CATEGORIES_FETCH_FAIL';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const GET_CATEGORY_START = 'GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const CATEGORY_CLEAN = 'CATEGORY_CLEAN';

export const getCategoriesStart = () => {
    return {
        type: CATEGORIES_FETCH_START
    }
}

export const getCategoriesSuccess = (json) => {
    return {
        type: CATEGORIES_FETCH_SUCCESS,
        data: json
    }
}

export const getCategoriesFail = (error) => {
    return {
        type: CATEGORIES_FETCH_FAIL,
        error: error
    }
}

export const getCategoryStart = () => {
    return {
        type: GET_CATEGORY_START
    }
}

export const getCategorySuccess = (json) => {
    return {
        type: GET_CATEGORY_SUCCESS,
        data: json
    }
}

export const getCategoryFail = (error) => {
    return {
        type: GET_CATEGORY_FAIL,
        error: error
    }
}

export const createCategoryStart = () => {
    return {
        type: CREATE_CATEGORY_START
    }
}

export const createCategorySuccess = (json) => {
    return {
        type: CREATE_CATEGORY_SUCCESS,
        data: json
    }
}

export const createCategoryFail = (error) => {
    return {
        type: CREATE_CATEGORY_FAIL,
        error: error
    }
}

export const updateCategoryStart = () => {
    return {
        type: UPDATE_CATEGORY_START
    }
}

export const updateCategorySuccess = (json) => {
    return {
        type: UPDATE_CATEGORY_SUCCESS,
        data: json
    }
}

export const updateCategoryFail = (error) => {
    return {
        type: UPDATE_CATEGORY_FAIL,
        error: error
    }
}

export const deleteCategoryStart = () => {
    return {
        type: DELETE_CATEGORY_START
    }
}

export const deleteCategorySuccess = (id) => {
    return {
        type: DELETE_CATEGORY_SUCCESS,
        data: id
    }
}

export const deleteCategoryFail = (error) => {
    return {
        type: DELETE_CATEGORY_FAIL,
        error: error
    }
}

export const categoryClean = () => {
    return {
        type: CATEGORY_CLEAN
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchCategories = (query) => {

    return function(dispatch) {
        dispatch(getCategoriesStart());
        return axiosInstance.get(`/services/category/?search=${query}`)
            .then(res => {
                dispatch(getCategoriesSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getCategoriesFail(error));
            })
    }
}

export const createCategory = (data) => {

    return function(dispatch) {
        dispatch(createCategoryStart());
        return axiosInstance.post(`/services/category/`, data)
            .then(res => {
                toast.success('Category created successfully', options);
                dispatch(createCategorySuccess(res.data));
            })
            .catch(error => {
                try {
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.name && error.response.data.name[0])
                            toast.error(error.response.data.name[0], options);
                    } else 
                        toast.error('Please check form fields', options);
                } catch(err) {
                    toast.error('Please check form fields', options);
                }
                dispatch(createCategoryFail(error));
            })
    }
}

export const getCategory = (id) => {

    return function(dispatch) {
        dispatch(getCategoryStart());
        return axiosInstance.get(`/services/category/${id}/`)
            .then(res => {
                dispatch(getCategorySuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getCategoryFail(error));
            })
    }
}

export const updateCategory = (id, data) => {

    return function(dispatch) {
        dispatch(updateCategoryStart());
        return axiosInstance.put(`/services/category/${id}/`, data)
            .then(res => {
                toast.success('Updated created successfully', options);
                dispatch(updateCategorySuccess(res.data));
            })
            .catch(error => {
                try {
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.name && error.response.data.name[0])
                            toast.error(error.response.data.name[0], options);
                    } else 
                        toast.error('Please check form fields', options);
                } catch(err) {
                    toast.error('Please check form fields', options);
                }
                dispatch(updateCategoryFail(error));
            })
    }
}

export const deleteCategory = (id) => {

    return function(dispatch) {
        dispatch(deleteCategoryStart());
        return axiosInstance.delete(`/services/category/${id}/`)
            .then(res => {
                toast.success('Deleted created successfully', options);
                dispatch(deleteCategorySuccess(id));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(deleteCategoryFail(error));
            })
    }
}