import {Routes, Route } from 'react-router-dom';
import history from './utils/history';
import CustomRouter from './utils/CustomRouter';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

//Layouts
import AuthLayout from './layout/AuthLayout';
import AdminLayout from './layout/AdminLayout';
import HomeLayout from './layout/HomeLayout';

//pages
import NotFound from './pages/404';
import SignIn from './pages/auth/SignIn';
import ForgotPassword from './pages/auth/ForgotPassword';
import ChangePassword from './pages/auth/ChangePassword';

import Dashboard from './pages/dashboard/Dashboard';
import Location from './pages/dashboard/Location';
import Category from './pages/dashboard/Category';
import Shop from './pages/dashboard/Shop';
import Admin from './pages/dashboard/Admin';
import AdMaker from './pages/dashboard/AdMaker';
import Home from './pages/Home';
import SearchZone from './pages/SearchZone';
import Profile from './pages/dashboard/Profile';
import { ToastContainer } from 'react-toastify';

function App() {
  	return (
    	<CustomRouter history={history}>
      		<Routes>
			  	<Route path="/auth" element={<AuthLayout/>}>
				  	<Route index element={<SignIn/>}/>
					<Route path="/auth/forgotpassword" element={<ForgotPassword/>}/>
					{/* <Route path="/password/reset/confirm/:uid/:token" element={<ChangePassword/>}/> */}
				</Route>
				<Route path="/admin" element={<AdminLayout/>}>
					<Route index element={<Dashboard/>}/>
					<Route path="/admin/location" element={<Location/>}/>
					<Route path="/admin/category" element={<Category/>}/>
					<Route path="/admin/shops" element={<Shop/>}/>
					<Route path="/admin/employees" element={<Admin/>}/>
					<Route path="/admin/ads" element={<AdMaker/>}/>
					<Route path="/admin/profile" element={<Profile/>}/>
				</Route>
				<Route path="/" element={<HomeLayout/>}>
					<Route index element={<Home/>}/>
					<Route path="/:id/:location" element={<SearchZone/>}/>
				</Route>
				<Route path="/password" element={<AuthLayout/>}>
					<Route path="/password/reset/confirm/:uid/:token" element={<ChangePassword/>}/> 
				</Route>
				<Route path="*" element={<NotFound/>}/>
          	</Routes>
          	<ToastContainer />
		</CustomRouter>
  	);
}

export default App;
