import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const ADMINS_FETCH_START = 'ADMINS_FETCH_START';
export const ADMINS_FETCH_SUCCESS = 'ADMINS_FETCH_SUCCESS';
export const ADMINS_FETCH_FAIL = 'ADMINS_FETCH_FAIL';

export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';

export const GET_ADMIN_START = 'GET_ADMIN_START';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';

export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';

export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const getAdminsStart = () => {
    return {
        type: ADMINS_FETCH_START
    }
}

export const getAdminsSuccess = (json) => {
    return {
        type: ADMINS_FETCH_SUCCESS,
        data: json
    }
}

export const getAdminsFail = (error) => {
    return {
        type: ADMINS_FETCH_FAIL,
        error: error
    }
}

export const getAdminStart = () => {
    return {
        type: GET_ADMIN_START
    }
}

export const getAdminSuccess = (json) => {
    return {
        type: GET_ADMIN_SUCCESS,
        data: json
    }
}

export const getAdminFail = (error) => {
    return {
        type: GET_ADMIN_FAIL,
        error: error
    }
}

export const createAdminStart = () => {
    return {
        type: CREATE_ADMIN_START
    }
}

export const createAdminSuccess = (json) => {
    return {
        type: CREATE_ADMIN_SUCCESS,
        data: json
    }
}

export const createAdminFail = (error) => {
    return {
        type: CREATE_ADMIN_FAIL,
        error: error
    }
}

export const updateAdminStart = () => {
    return {
        type: UPDATE_ADMIN_START
    }
}

export const updateAdminSuccess = (json) => {
    return {
        type: UPDATE_ADMIN_SUCCESS,
        data: json
    }
}

export const updateAdminFail = (error) => {
    return {
        type: UPDATE_ADMIN_FAIL,
        error: error
    }
}

export const deleteAdminStart = () => {
    return {
        type: DELETE_ADMIN_START
    }
}

export const deleteAdminSuccess = (id) => {
    return {
        type: DELETE_ADMIN_SUCCESS,
        data: id
    }
}

export const deleteAdminFail = (error) => {
    return {
        type: DELETE_ADMIN_FAIL,
        error: error
    }
}

export const getPofileStart = () => {
    return {
        type: GET_PROFILE_START
    }
}

export const getPofileSuccess = (json) => {
    return {
        type: GET_PROFILE_SUCCESS,
        data: json
    }
}

export const getPofileFail = (error) => {
    return {
        type: GET_PROFILE_FAIL,
        error: error
    }
}

export const updatePofileStart = () => {
    return {
        type: UPDATE_PROFILE_START
    }
}

export const updatePofileSuccess = (json) => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        data: json
    }
}

export const updatePofileFail = (error) => {
    return {
        type: UPDATE_PROFILE_FAIL,
        error: error
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchAdmins = (query) => {

    return function(dispatch) {
        dispatch(getAdminsStart());
        return axiosInstance.get(`/accounts/admins/?search=${query}`)
            .then(res => {
                dispatch(getAdminsSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getAdminsFail(error));
            })
    }
}

export const createAdmin = (data) => {

    return function(dispatch) {
        dispatch(createAdminStart());
        return axiosInstance.post(`/accounts/admins/`, data)
            .then(res => {
                toast.success('User created successfully', options);
                dispatch(createAdminSuccess(res.data));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.location && error.response.data.location[0])
                            toast.error("This location already have an admin", options);
                        else if(error.response.data && error.response.data.email && error.response.data.email[0])
                            toast.error(error.response.data.email[0], options);
                        else if(error.response.data && error.response.data.profile_pic && error.response.data.profile_pic[0])
                            toast.error(error.response.data.profile_pic[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(createAdminFail(error));
            })
    }
}

export const getAdmin = (id) => {

    return function(dispatch) {
        dispatch(getAdminStart());
        return axiosInstance.get(`/accounts/admins/${id}/`)
            .then(res => {
                dispatch(getAdminSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getAdminFail(error));
            })
    }
}

export const updateAdmin = (id, data) => {

    return function(dispatch) {
        dispatch(updateAdminStart());
        return axiosInstance.patch(`/accounts/admins/${id}/`, data)
            .then(res => {
                toast.success('User updated successfully', options);
                dispatch(updateAdminSuccess(res.data));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.location && error.response.data.location[0])
                            toast.error("This location already have an admin", options);
                        else if(error.response.data && error.response.data.email && error.response.data.email[0])
                            toast.error(error.response.data.email[0], options);
                        else if(error.response.data && error.response.data.profile_pic && error.response.data.profile_pic[0])
                            toast.error(error.response.data.profile_pic[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(updateAdminFail(error));
            })
    }
}

export const deleteAdmin = (id) => {

    return function(dispatch) {
        dispatch(deleteAdminStart());
        return axiosInstance.delete(`/accounts/admins/${id}/`)
            .then(res => {
                toast.success('User deleted successfully', options);
                dispatch(deleteAdminSuccess(id));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(deleteAdminFail(error));
            })
    }
}

export const getProfile = () => {

    return function(dispatch) {
        dispatch(getPofileStart());
        return axiosInstance.get(`/accounts/profile/-1/`)
            .then(res => {
                dispatch(getPofileSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getPofileFail(error));
            })
    }
}

export const updateProfile = (data) => {

    return function(dispatch) {
        dispatch(updatePofileStart());
        return axiosInstance.patch(`/accounts/profile/-1/`, data)
            .then(res => {
                toast.success('Profile updated successfully', options);
                dispatch(updatePofileSuccess(res.data));
                dispatch(getProfile());
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(updatePofileFail(error));
            })
    }
}