import React from 'react';
import {Grid, Container} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from '../../redux/dashboard/actions';

import CountCard from '../../components/admin/CountCard';


const Dashboard = () => {
	const dispatch = useDispatch();

	const { isLoading, counts} = useSelector(state => {
        return {
            isLoading: state.dashboard.isLoading,
            counts: state.dashboard.counts
        };
    });

	React.useEffect(() => {
		dispatch(fetchDashboard());
		// eslint-disable-next-line
	}, []);

	return (
		<Container>
			<br/><br/>
			<Grid container spacing={2}>
				<Grid item xs={6} md={3}>
					<CountCard title="Services" count={counts.total_shops} loading={isLoading}/>
				</Grid>
				<Grid item xs={6} md={3}>
					<CountCard title="Locations" count={counts.total_locations} loading={isLoading}/>
				</Grid>
				<Grid item xs={6} md={3}>
					<CountCard title="Categories" count={counts.total_categories} loading={isLoading}/>
				</Grid>
				<Grid item xs={6} md={3}>
					<CountCard title="Admins" count={counts.total_admins} loading={isLoading}/>
				</Grid>
			</Grid>
			<br/><br/>
		</Container>
	);
}

export default Dashboard;