import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../redux/auth/actions';
import { emailValidation } from '../../utils/validation';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';



const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = React.useState({email: ''});
    const [errorText, setErrorText] = React.useState({email: ''});

    const { isLoading } = useSelector(state => {
        return { isLoading: state.auth.isLoading };
    });

    const handleChange = (e) => {
        setEmail({...email, email: e.target.value});
    }

    const handleSubmit = () => {
        if (validateForm()){
            let data = {email: email.email};
            dispatch(forgotPassword(data));
        }
    }

    const validateForm = () => {
        let fields = email;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = emailValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    return(        
        <Box>
            <Typography variant='h5' style={{color: 'grey'}}>Forgot Password</Typography><br/>
            <InputLabel htmlFor="outlined-adornment-password">E-mail</InputLabel>
            <TextField
                id="outlined-basic"
                size='small'
                name='email'
                onChange={handleChange}
                fullWidth
                style={{marginBottom: 4, width: '260px'}}
                error={errorText.email}
                helperText={errorText.email}
                variant="outlined"
            /><br/>                   
                    
            <Box className='link'>
                <Link to="/auth"  style={{color: "#45b3e0", textDecoration: "none"}}>
                    <small>SignIn</small>
                </Link>
            </Box>
                    
            <Button 
                className='button' 
                onClick={handleSubmit}
                disabled={isLoading}
                startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}>
                    Send Mail
            </Button>
        </Box>          
    );
}

export default ForgotPassword;