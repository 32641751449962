import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const SUGGESTIONS_START = 'SUGGESTIONS_START';
export const SUGGESTIONS_SUCCESS = 'SUGGESTIONS_SUCCESS';
export const SUGGESTIONS_FAIL = 'SUGGESTIONS_FAIL';

export const FILTERING_START = 'FILTERING_START';
export const FILTERING_SUCCESS = 'FILTERING_SUCCESS';
export const FILTERING_FAIL = 'FILTERING_FAIL';

export const HOME_START = 'HOME_START';
export const HOME_SUCCESS = 'HOME_SUCCESS';
export const HOME_FAIL = 'HOME_FAIL';

export const getSuggestionStart = () => {
    return {
        type: SUGGESTIONS_START
    }
}

export const getSuggestionSuccess = (json) => {
    return {
        type: SUGGESTIONS_SUCCESS,
        data: json
    }
}

export const getSuggestionFail = (error) => {
    return {
        type: SUGGESTIONS_FAIL,
        error: error
    }
}

export const filteringStart = () => {
    return {
        type: FILTERING_START
    }
}

export const filteringSuccess = (json) => {
    return {
        type: FILTERING_SUCCESS,
        data: json
    }
}

export const filteringFail = (error) => {
    return {
        type: FILTERING_FAIL,
        error: error
    }
}

export const getHomeStart = () => {
    return {
        type: HOME_START
    }
}

export const getHomeSuccess = (json) => {
    return {
        type: HOME_SUCCESS,
        data: json
    }
}

export const getHomeFail = (error) => {
    return {
        type: HOME_FAIL,
        error: error
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchSuggestions = (name, location) => {
    var encodedLocation = encodeURIComponent(location);
    var encodedName = encodeURIComponent(name);

    return function(dispatch) {
        dispatch(getSuggestionStart());
        return axiosInstance.get(`/home/service-filter/recommendations/?location=${encodedLocation}&name=${encodedName}`)
            .then(res => {
                dispatch(getSuggestionSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getSuggestionFail(error));
            })
    }
}

export const getFilteredServices = (name, location, is_categ, page) => {
    var encodedLocation = encodeURIComponent(location);
    var encodedName = encodeURIComponent(name);
    let url = `/services/filtered_services/?page=${page}&location=${encodedLocation}&name=${encodedName}&is_category=${is_categ}`;

    return function(dispatch) {
        dispatch(filteringStart());
        return axiosInstance.get(url)
            .then(res => {
                dispatch(filteringSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(filteringFail(error));
            })
    }
}

export const getHome = () => {

    return function(dispatch) {
        dispatch(getHomeStart());
        return axiosInstance.get('/home/')
            .then(res => {
                dispatch(getHomeSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getHomeFail(error));
            })
    }
}
