import React from 'react';
import PageHeader from '../../components/admin/PageHeader';
import { makeStyles } from '@mui/styles';
import { Paper, TextField, Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { DataGrid } from '@mui/x-data-grid';
import CategoryForm from '../../components/forms/CategoryForm';
import ConfirmDialog from '../../components/forms/ConfirmDialog';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, getCategory, deleteCategory } from '../../redux/category/actions';
import { capitalize } from  '../../utils/capitalize';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: '10px',
        padding: '10px',
        "@media (min-width: 1280px)": {
          margin: "0px"        
      },
    },
    searchInput: {
        width: '75%'
    }
})); 

const Category = () => {

    const options = {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
    };

    const columns = [
        { 
            field: 'name', 
            headerName: 'Category',
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { 
            field: 'actions', 
            headerName: 'Actions', 
            align: "right",
            sortable: false,
            renderCell: (cellValues) => {
            return (
                <>
                    <IconButton color="primary" aria-label="upload picture" 
                        component="span" onClick={() => getDetail(cellValues.id)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="error" aria-label="upload picture" 
                        component="span" onClick={() => openDialog(cellValues.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            );
          }
        }
    ];

    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [newe, setNew] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [delId, setDelId] = React.useState();


    const { isLoading, categories, category, success } = useSelector(state => {
        return {
            isLoading: state.category.isLoading,
            categories: state.category.categories,
            category: state.category.category,
            success: state.category.success
        };
    });

    React.useEffect(() => {
        dispatch(fetchCategories(''));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if(success) handleClose();
        // eslint-disable-next-line
    }, [success]);

    const handleSearch = (e) => {
        dispatch(fetchCategories(e.target.value));
    }

    const getDetail = (id) => {
        setNew(false);
        setOpen(true);
        dispatch(getCategory(id));
    }

    const addNew = () => {
        setNew(true);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setNew(true);
    }

    const openDialog = (id) => {
        var user_role = localStorage.getItem('user_role');
        if (user_role === "super_user"){
            setDelId(id);
            setDialog(true);
        }
        else
            toast.error('Deletion not possible for admin', options);            
    }

    const closeDialog = () => {
        setDialog(false);
    }

    const handleDelete = () => {
        dispatch(deleteCategory(delId));
        setDialog(false);
    }

    return (
        <div id="datatable">
           <PageHeader
                title="Categories"
                subTitle="Add, Edit Delete Categories"
                icon={<ViewCarouselIcon fontSize="large" />}
            />

            <Paper className={classes.pageContent}>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            name="search"
                            size="small"
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row-reverse">      
                            <Button variant="outlined" size="large" onClick={addNew}>Add New</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <div style={{  width: '100%' }}>
                    <DataGrid
                        rows={categories}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        autoHeight={true}
                        loading={isLoading}
                    />
                </div>
            </Paper>
            <CategoryForm
                open={open}
                handleClose={handleClose}
                category={category}
                newe={newe}
            /> 

            <ConfirmDialog
                open={dialog}
                handleClose={closeDialog}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default Category;