import * as React from 'react';
import { styled } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import RedeemIcon from '@mui/icons-material/Redeem';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { useNavigate } from 'react-router-dom';
import DrawerMiniCard from './DrawerMiniCard';

import './style.css';

const drawerWidth = 260;

// var name = '';
// var user_role = '';
// var profile_pic = '';

// const getBasicInfo = () => {
// 	name = localStorage.getItem('name');
// 	user_role = localStorage.getItem('user_role');
// 	profile_pic = process.env.REACT_APP_API_URL + localStorage.getItem('profile_pic');
// }

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(9)} + 0.5px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar
}));

const AdminDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
     	...openedMixin(theme),
     	'& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
     	...closedMixin(theme),
     	'& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const generalItems = [
	{
		id: 1,
		name: 'Dashboard',
		link: "/admin",
		admin: true
	},
	{
		id: 2,
		name: 'Location',
		link: "/admin/location",
		admin : false
	},
	{
		id: 3,
		name: 'Category',
		link: "/admin/category",
		admin : true
	},
	{
		id: 4,
		name: 'Shops and Services',
		link: "/admin/shops",
		admin: true
	},
	{
		id: 5,
		name: 'Ads Maker',
		link: "/admin/ads",
		admin: false
	},
	{
		id: 6,
		name: 'Admins',
		link: "/admin/employees",
		admin: false
	}
];

const returnItem = (sIndex, index) => {
	if (index === 0)
		return <DashboardIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
	if (index === 1)
		return <LocationOnIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
	if (index === 2)
		return <ViewCarouselIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
	if (index === 3)
		return <StoreMallDirectoryIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
	if (index === 4)
		return <RedeemIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
	if (index === 5)
		return <SupervisorAccountIcon sx={{color: (sIndex === index) ? "#15D8FB" : "#637381"}}/>
}

const Drawer = (props) => {

	const [state, setState] = React.useState({
        name : '',
		user_role : '',
		profile: '',
		profile_pic : ''
    });
     
	React.useEffect(() => {
        getBasicInfo();
        // eslint-disable-next-line
    }, []);

	const getBasicInfo = () => {
		setState({...state,
			name : localStorage.getItem('name'),
			user_role : localStorage.getItem('user_role'),
			profile : localStorage.getItem('profile_pic'),
			profile_pic : process.env.REACT_APP_API_URL + localStorage.getItem('profile_pic')
		})
	}

	let navigate = useNavigate();
	const [selectedIndex, setSelectedIndex] = React.useState(0);

  	const handleListItemClick = (event, index, link) => {
  		navigate(link);
    	setSelectedIndex(index);
  	};

	return (
		<AdminDrawer
			className="admin-drawer"
			variant={props.variant} 
			open={props.open}
			style={{borderRight: "1px solid #f2f2f2"}}
		>

	        <DrawerHeader>
	          	{props.open ? (<IconButton onClick={props.handleDrawerClose}>
	            	<ChevronLeftIcon />	          	
	            	</IconButton>) : (
	          		<IconButton onClick={props.handleDrawerOpen} style={{marginRight: "10px"}}>
	            		<ChevronRightIcon />
	          		</IconButton>
	          	)}
	        </DrawerHeader>
	        <br/>
	        {props.open ? <DrawerMiniCard title={state.name} sub={state.user_role} image={state.profile}/> :
	    		<div className="mini-avatar">
					{state.profile === '' ? 
						<Avatar style={{backgroundColor: 'blueviolet'}}>{state.name.charAt(0)}</Avatar> : 
						<Avatar src={state.profile_pic}/>
					}
	    			<br/>
	    		</div>
	    	}<br/>
	        {props.open && <Grid container style={{marginLeft: "15px"}}>
	        	<Typography variant="caption">GENERAL</Typography>
	        </Grid>}

			<List className="drawer-list">
				{
					generalItems.map((item, index) => (
						(state.user_role === 'admin' && item.admin === false ? null :
							<React.Fragment key={item.id}>
								<ListItemButton
									className={selectedIndex === index ? "drawer-item-selected" : "drawer-item"}
									selected={selectedIndex === index}
									onClick={(event) => handleListItemClick(event, index, item.link)}
								>
									<ListItemIcon color="primary">
										{returnItem(selectedIndex, index)}
									</ListItemIcon>
									<ListItemText primary={item.name} 
									sx={{color: (selectedIndex === index) ? "#15D8FB" : "#637381"}}/>
								</ListItemButton>
								<div style={{height: "5px"}}></div>
							</React.Fragment>
					 	)
					))
				}
		    </List>
      	</AdminDrawer>
	);
}

export default Drawer;
