import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import './home.css';
import Logo from '../../assets/images/logo.png';

export default function PageFooter() {
    return (
        <div>
            <Box className='main-footer'>
                <Grid container spacing={4}>
                    <Grid className="logo" item xs={12} sm={12} md={4} lg={4} xl={4}  style={{paddingTop: "25px"}}>
                        <img src={Logo} alt="logo" height="150px"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{textAlign: "center"}}>
                        <br/>
                        <Typography className='heading' variant="h5">My Locality Info</Typography> 
                        <Typography>MyLocalityInfo is a digital service provider for local businesses making people's life easier with a wide range of solutions.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{textAlign: "center"}}>
                        <br/>
                        <Typography className='heading' variant="h5">Contact Us</Typography> 
                        <Typography variant="body2">
                            Malabar Innovation Entrepreneurship Zone
                            Build No: 446, Mangattuparamba
                            Near Kannur University Campus
                            Kalliasseri, Kannur, Kerala 670567
                        </Typography>
                        <Typography>Call now - +919847014139</Typography>
                        <Grid item >
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box className='sub-footer'>
                <Typography>@CopyRight My Locality Info</Typography>
                <IconButton>
                    <FacebookIcon className='icon'/>
                </IconButton>
                <IconButton>
                    <InstagramIcon className='icon'/>
                </IconButton>
                <IconButton>
                    <TwitterIcon className='icon'/>
                </IconButton>
            </Box>
        </div>
    );
}
