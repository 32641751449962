import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const LOCATIONS_FETCH_START = 'LOCATIONS_FETCH_START';
export const LOCATIONS_FETCH_SUCCESS = 'LOCATIONS_FETCH_SUCCESS';
export const LOCATIONS_FETCH_FAIL = 'LOCATIONS_FETCH_FAIL';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const LOCATION_FETCH_START = 'LOCATION_FETCH_START';
export const LOCATION_FETCH_SUCCESS = 'LOCATION_FETCH_SUCCESS';
export const LOCATION_FETCH_FAIL = 'LOCATION_FETCH_FAIL';

export const LOCATION_CLEAN = 'LOCATION_CLEAN';

export const getLocationsStart = () => {
    return {
        type: LOCATIONS_FETCH_START
    }
}

export const getLocationsSuccess = (json) => {
    return {
        type: LOCATIONS_FETCH_SUCCESS,
        data: json
    }
}

export const getLocationsFail = (error) => {
    return {
        type: LOCATIONS_FETCH_FAIL,
        error: error
    }
}

export const deleteLocationStart = () => {
    return {
        type: DELETE_LOCATION_START
    }
}

export const deleteLocationSuccess = (json) => {
    return {
        type: DELETE_LOCATION_SUCCESS,
        data: json
    }
}

export const deleteLocationFail = (error) => {
    return {
        type: DELETE_LOCATION_FAIL,
        error: error
    }
}

export const updateLocationStart = () => {
    return {
        type: UPDATE_LOCATION_START
    }
}

export const updateLocationSuccess = (json) => {
    return {
        type: UPDATE_LOCATION_SUCCESS,
        data: json
    }
}

export const updateLocationFail = (error) => {
    return {
        type: UPDATE_LOCATION_FAIL,
        error: error
    }
}

export const createLocationStart = () => {
    return {
        type: CREATE_LOCATION_START
    }
}

export const createLocationSuccess = (json) => {
    return {
        type: CREATE_LOCATION_SUCCESS,
        data: json
    }
}

export const createLocationFail = (error) => {
    return {
        type: CREATE_LOCATION_FAIL,
        error: error
    }
}

export const getLocationStart = () => {
    return {
        type: LOCATION_FETCH_START
    }
}

export const getLocationSuccess = (json) => {
    return {
        type: LOCATION_FETCH_SUCCESS,
        data: json
    }
}

export const getLocationFail = (error) => {
    return {
        type: LOCATION_FETCH_FAIL,
        error: error
    }
}

export const locationClean = () => {
    return {
        type: LOCATION_CLEAN
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchLocations = (query) => {

    return function(dispatch) {
        dispatch(getLocationsStart());
        return axiosInstance.get(`/location/?search=${query}`)
            .then(res => {
                dispatch(getLocationsSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getLocationsFail(error));
            })
    }
}

export const deleteLocation = (id) => {

    return function(dispatch) {
        dispatch(deleteLocationStart());
        return axiosInstance.delete(`/location/${id}/`)
            .then(res => {
                toast.success('Location deleted successfully', options);
                dispatch(deleteLocationSuccess(id));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(deleteLocationFail(error));
            })
    }
}

export const updateLocation = (id, data) => {

    return function(dispatch) {
        dispatch(updateLocationStart());
        return axiosInstance.patch(`/location/${id}/`, data)
            .then(res => {
                toast.success('Location updated successfully', options);
                dispatch(updateLocationSuccess(res.data));
            })
            .catch(error => {
                try {
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.name && error.response.data.name[0])
                            toast.error(error.response.data.name[0], options);
                        else if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(updateLocationFail(error));
            })
    }
}

export const createLocation = (data) => {

    return function(dispatch) {
        dispatch(createLocationStart());
        return axiosInstance.post(`/location/`, data)
            .then(res => {
                toast.success('Location created successfully', options);
                dispatch(createLocationSuccess(res.data));
            })
            .catch(error => {
                try {
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.name && error.response.data.name[0])
                            toast.error(error.response.data.name[0], options);
                        else if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(createLocationFail(error));
            })
    }
}

export const fetchLocation = (id) => {

    return function(dispatch) {
        dispatch(getLocationStart());
        return axiosInstance.get(`/location/${id}/`)
            .then(res => {
                dispatch(getLocationSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getLocationFail(error));
            })
    }
}