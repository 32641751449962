import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Footer from '../components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '../components/admin/AppBar';
import Drawer from '../components/admin/Drawer';
import { styled, useTheme } from '@mui/material/styles';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AdminLayout = () => {
	const theme = useTheme();

	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
	    setOpen(true);
	};

	const handleDrawerClose = () => {
	    setOpen(false);
	};

	return (JSON.parse(localStorage.getItem("logged_in")) === true) ? (
		<Box sx={{ 
				display: 'flex', 
				overflowX: 'hidden', 
			}}>
      		<CssBaseline />
      		<AppBar 
      			position="fixed" 
      			open={open} 
      			handleDrawerOpen={handleDrawerOpen}
      			drawerWidth={drawerWidth}
      		/>
      		<Drawer
      			variant="permanent" 
      			open={open}
      			handleDrawerClose={handleDrawerClose}
      			handleDrawerOpen={handleDrawerOpen}
      			theme={theme}
      		/>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				<Outlet/>
				<br/><br/><br/>
				<Footer/>
			</Box>
		</Box>
	) : <Navigate to="/auth"/>;
}

export default AdminLayout;
