import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import ImagePicker from './ImagePicker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations } from '../../redux/location/actions';
import Radio from '@mui/material/Radio';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const AdsForm = (props) => {
    const [imageSize, setImageSize] = React.useState({
        width: 400,
        height: 200
    });
    const [locDisable, setLocDisable] = React.useState(false);

    const dispatch = useDispatch();
    const tempValue = {
        id: null,
        name: 'Common',
        image: ''
    };

    const { isLoading, locations, adLoading } = useSelector(state => {
        return {
            isLoading: state.location.isLoading,
            locations: state.location.locations,
            adLoading: state.ad.isLoading,
        };
    });

    React.useEffect(() => {
        dispatch(fetchLocations(''));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props.ad && props.ad.ad_type === 'large') {
            setLocDisable(true);
            setImageSize({
                ...imageSize,
                width: 1000,
                height: 300
            });
        } else {
            setLocDisable(false);
            setImageSize({
                ...imageSize,
                width: 400,
                height: 200
            });
        }

        // eslint-disable-next-line
    }, [props.ad]);

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Ads Form
                </Typography>

                <InputLabel htmlFor="adtype">Ad Type</InputLabel>
                <Radio
                    checked={props.ad.ad_type === 'small'}
                    onChange={props.handleTypeChange}
                    value="small"
                    name="radio-buttons"
                />Small
                <Radio
                    checked={props.ad.ad_type === 'large'}
                    onChange={props.handleTypeChange}
                    value="large"
                    name="radio-buttons"
                />Large

                {!locDisable ? 
                <div>
                <InputLabel htmlFor="auto-comp">Location</InputLabel>
                <Autocomplete
                    disableClearable
                    disablePortal
                    loading={isLoading}
                    id="combo-box-demo"
                    fullWidth
                    size='small'
                    style={{marginBottom: 10}}
                    options={locations}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={(props.ad && props.ad.location) ?? tempValue}
                    onChange={(event, newValue) => props.handleSelect(newValue)}
                    renderInput={(params) => <TextField variant="outlined" {...params} />}
                /> </div> : null}

                <InputLabel htmlFor="dte-pckr">Expiry Date</InputLabel>
                <TextField
                    id="date"
                    type="date"
                    size="small"
                    fullWidth
                    value={props.ad.expiry}
                    error={props.errorText.expiry}
                    helperText={props.errorText.expiry}
                    style={{marginBottom: 10}}
                    onChange={props.handleDateChange}
                />

                <InputLabel htmlFor="imgpckr">Image</InputLabel>
                <ImagePicker 
                    setImage={props.setImage} 
                    api_image={props.ad.image} 
                    widths={imageSize.width} 
                    heights={imageSize.height}/>
                <small style={{color: "red"}}>{props.errorText.image}</small><p></p>

                <Button 
                    onClick={props.submitForm} 
                    style={{textTransform: 'none', color: 'white', backgroundColor: '#fba82c'}}
                    disabled={adLoading}
                    startIcon={adLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                >
                    Submit
                </Button>
            </Box>
        </Modal>    
    );
}

export default AdsForm;