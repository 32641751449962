import axiosInstance from '../../utils/axios';
import history from '../../utils/history';
import { toast } from 'react-toastify';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS  = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS  = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS  = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';


export const authStart = () => {
    return {
        type: AUTH_START
    }
}

export const authSuccess = (json) => {
    return {
        type: AUTH_SUCCESS,
        data: json
    }
}

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    }
}

export const logoutStart = () => {
    return {
        type: LOGOUT_START
    }
}

export const logoutSuccess = (json) => {
    return {
        type: LOGOUT_SUCCESS
    }
}

export const logoutFail = (error) => {
    return {
        type: LOGOUT_FAIL,
        error: error
    }
}

export const forgotPasswordStart = () => {
    return {
        type: FORGOT_PASSWORD_START
    }
}

export const forgotPasswordSuccess = (json) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        data: json
    }
}

export const forgotPasswordFail = (error) => {
    return {
        type: FORGOT_PASSWORD_FAIL,
        error: error
    }
}

export const passwordResetStart = () => {
    return {
        type: PASSWORD_RESET_START
    }
}

export const passwordResetSuccess = (json) => {
    return {
        type: PASSWORD_RESET_SUCCESS,
        data: json
    }
}

export const passwordResetFail = (error) => {
    return {
        type: PASSWORD_RESET_FAIL,
        error: error
    }
}

export const changePasswordStart = () => {
    return {
        type: CHANGE_PASSWORD_START
    }
}

export const changePasswordSuccess = (json) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        data: json
    }
}

export const changePasswordFail = (error) => {
    return {
        type: CHANGE_PASSWORD_FAIL,
        error: error
    }
}


const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const userLogin = (loginCred) => {

    return function(dispatch) {
        dispatch(authStart());
        return axiosInstance.post("/accounts/user_login/", loginCred)
            .then(res => {
                dispatch(authSuccess(res.data));
                axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + res.data.access_token;
                localStorage.setItem("user_id", res.data.user_id);
                localStorage.setItem("user_role", res.data.user_role);
                localStorage.setItem("access_token", res.data.access_token);
                localStorage.setItem("refresh_token", res.data.refresh_token);
                localStorage.setItem("name", res.data.name);
                localStorage.setItem("profile_pic", res.data.profile_pic);
                localStorage.setItem("logged_in", true);
                history.replace("/admin");
            })
            .catch(error => {
                toast.error('Invalid user credentials', options);
                dispatch(authFail(error));
                localStorage.clear();
            })
    }
}

export const userLogout = () => {

    return function(dispatch) {
        dispatch(logoutStart());
        return axiosInstance.post("/accounts/user_logout/", {})
            .then(res => {
                dispatch(logoutSuccess(res.data));
                localStorage.clear();
                axiosInstance.defaults.headers['Authorization'] = null;
                history.replace("/");
            })
            .catch(error => {
                toast.info('Session expired please refresh site', options);
                dispatch(logoutFail(error));
            })
    }
}

export const forgotPassword = (email) => {

    return function(dispatch) {
        dispatch(forgotPasswordStart());
        return axiosInstance.post("/auth/users/reset_password/", email)
            .then(res => {
                toast.success('A link has been sent to your email', options);
                dispatch(forgotPasswordSuccess(res.data));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data[0])
                            toast.error(error.response.data[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(forgotPasswordFail(error));
            })
    }
}

export const passwordReset = (data) => {

    return function(dispatch) {
        dispatch(forgotPasswordStart());
        return axiosInstance.post("/auth/users/reset_password_confirm/", data)
            .then(res => {
                toast.success('Password reset completed', options);
                dispatch(forgotPasswordSuccess(res.data));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.new_password[0])
                            toast.error(error.response.data.new_password[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(forgotPasswordFail(error));
            })
    }
}

export const changePassword = (data) => {

    return function(dispatch) {
        dispatch(changePasswordStart());
        return axiosInstance.put("/accounts/change-password/-1/", data)
            .then(res => {
                toast.success('Password changed successfully', options);
                dispatch(changePasswordSuccess(res.data));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.old_password) 
                            toast.error(error.response.data.old_password, options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(changePasswordFail(error));
            })
    }
}