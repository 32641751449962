import React from 'react';
import PageHeader from '../../components/admin/PageHeader';
import RedeemIcon from '@mui/icons-material/Redeem';
import { makeStyles } from '@mui/styles';
import { Paper, TextField, Button, Grid} from '@mui/material';
import AdCard from '../../components/admin/AdCard';
import AdsForm from '../../components/forms/AdsForm';
import ConfirmDialog from '../../components/forms/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAds, deleteAd, 
         createAd, updateAd,
         fetchAd } from '../../redux/ads/actions';
import Pagination from '@mui/material/Pagination';

import {
    expiryValidation,
    imageValidation
} from '../../utils/validation';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: '10px',
        padding: '10px',
        "@media (min-width: 1280px)": {
          margin: "0px"        
      },
    },
    searchInput: {
        width: '75%'
    }
}));

const AdMaker = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState('');
    const [dialog, setDialog] = React.useState(false);
    const [delId, setDelId] = React.useState();

    const [ad, setAd] = React.useState({
    	id: null, 
    	location: null, 
    	image: null, 
    	expiry: null,
        ad_type: "small"
    });
    const [blobImg, setBlobImg] = React.useState(null);
    const [errorText, setErrorText] = React.useState({expiry: '', image: ''});

    const { isLoading, ads, adv, success } = useSelector(state => {
        return {
            isLoading: state.ad.isLoading,
            ads: state.ad.ads,
            adv: state.ad.ad,
            success: state.ad.success
        };
    });

    React.useEffect(() => {
        dispatch(fetchAds(page, search));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (adv !== {}) {
            setAd(adv);
            setBlobImg(null);
        }
        // eslint-disable-next-line
    }, [adv]);

    React.useEffect(() => {
        if(success) handleClose();
        // eslint-disable-next-line
    }, [success]);

    const handlePageChange = (event, value) => {
        setPage(value);
        dispatch(fetchAds(value, search));
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
        setPage(1);
        dispatch(fetchAds(1, e.target.value));
    }

    const addNew = () => {
        setAd({
        	...ad,
        	id: null, 
        	location: null, 
        	image: null,
        	expiry: null,
            ad_type: "small"
        });
        setBlobImg(null);
        setOpen(true);
    }

    const handleSelect = (loc) => {
		 setAd({
        	...ad,
        	location: loc,
        });
    }

    const handleTypeChange = (e) => {
        setAd({
            ...ad,
            ad_type: e.target.value,
        });
    }

    const handleDateChange = (e) => {
    	setAd({
    		...ad,
    		expiry: e.target.value
    	});
    }

    const setImage = (imageBlob) => {
        setAd({...ad, image: 'set'});
        setBlobImg(imageBlob);
    }

    const appendFormData = () => {
        let formData = new FormData();

        formData.append("ad_type", ad.ad_type);
        
        if(ad.location !== null && !isNaN(Number(ad.location.id)))
            formData.append("location", ad.location.id);
        
        if(blobImg !== null)
            formData.append("image", blobImg);

        if(ad.expiry !== null)
            formData.append("expiry", ad.expiry);
        
        return formData;
    }

    const submitForm = () => {
        if (validateForm()){
            let fd = appendFormData();
            if (ad.id === null)
                dispatch(createAd(fd, page, search));
            else
                dispatch(updateAd(ad.id, fd, page, search));
        }
    }

    const validateForm = () => {
        let fields = ad;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = expiryValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = imageValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const deleteAdv = () => {
        let cPage = page;
        
        if (ads.current_page === ads.total && ads.results.length === 1 && ads.total !== 1)
            cPage = cPage - 1;
        else if(ads.results.length === 1)
            cPage = 1;

        setPage(cPage);
        dispatch(deleteAd(delId, cPage, search));
        setDialog(false);
    }

    const retrieveAd = (id) => {
    	setOpen(true);
        setBlobImg(null);
    	dispatch(fetchAd(id));
    }

    const handleClose = () => {
        setAd({
            ...ad,
            id: null, 
            location: null, 
            image: null,
            expiry: null
        });
        setBlobImg(null);
        setOpen(false);
        setErrorText({...errorText, expiry: '', image: ''});
    }

    const openDialog = (id) => {
        setDelId(id);
        setDialog(true);
    }

    const closeDialog = () => {
        setDialog(false);
    }

	return (
		<>
			<p>{isLoading}</p>
 			<PageHeader
                title="Advertisement"
                subTitle="Add, Preview Delete Ads"
                icon={<RedeemIcon  fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            label="Enter Location"
                            name="search"
                            size="small"
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row-reverse">      
                            <Button variant="outlined" size="large" onClick={addNew}>Add New</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper><br/>
            <Grid container spacing={2}>
            	{ads && ads.results && ads.results.map((ad, index) => (
            		<Grid key={index} item sm={12} md={4}>
            		<AdCard  
            			ad={ad} 
            			deleteAd={() => openDialog(ad.id)}
            			retrieveAd={() => retrieveAd(ad.id)}
            		/>
            		</Grid>
            	))}
            </Grid>
			<AdsForm
				open={open}
                errorText={errorText}
                handleClose={handleClose}
                ad={ad}
                submitForm={submitForm}
                setImage={setImage}
                handleSelect={handleSelect}
                handleDateChange={handleDateChange}
                handleTypeChange={handleTypeChange}
			/>
            <br/><br/>
            <Grid container>
                <Pagination count={ads && ads.total} page={page} onChange={handlePageChange} />
            </Grid>

            <ConfirmDialog
                open={dialog}
                handleClose={closeDialog}
                handleDelete={deleteAdv}
            />
		</>
	);
}

export default AdMaker;