import { combineReducers } from 'redux';

import authReducer from './auth/reducers';
import locationReducer from './location/reducers';
import categoryReducer from './category/reducers';
import adReducer from './ads/reducers';
import adminReducer from './admin/reducers';
import dashboardReducer from './dashboard/reducers';
import serviceReducer from './service/reducers';
import filterReducer from './filters/reducers';

const rootReducer = combineReducers({
	auth: authReducer,
	location: locationReducer,
	category: categoryReducer,
	ad: adReducer,
	admin: adminReducer,
	dashboard: dashboardReducer,
	service: serviceReducer,
	filters: filterReducer
});

export default rootReducer;