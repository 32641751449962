import React from 'react';
import { Outlet } from 'react-router-dom';
import PageFooter from '../components/home/PageFooter';

const HomeLayout = () => {
	return (
        <>
            <Outlet/>
            <PageFooter/>
        </>
	);
}

export default HomeLayout;
