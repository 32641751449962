import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const ADS_FETCH_START = 'ADS_FETCH_START';
export const ADS_FETCH_SUCCESS = 'ADS_FETCH_SUCCESS';
export const ADS_FETCH_FAIL = 'ADS_FETCH_FAIL';

export const DELETE_AD_START = 'DELETE_AD_START';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DELETE_AD_FAIL = 'DELETE_AD_FAIL';

export const UPDATE_AD_START = 'UPDATE_AD_START';
export const UPDATE_AD_SUCCESS = 'UPDATE_AD_SUCCESS';
export const UPDATE_AD_FAIL = 'UPDATE_AD_FAIL';

export const CREATE_AD_START = 'CREATE_AD_START';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAIL = 'CREATE_AD_FAIL';

export const AD_FETCH_START = 'AD_FETCH_START';
export const AD_FETCH_SUCCESS = 'AD_FETCH_SUCCESS';
export const AD_FETCH_FAIL = 'AD_FETCH_FAIL';

export const LOCAL_ADS_START = 'LOCAL_ADS_START';
export const LOCAL_ADS_SUCCESS = 'LOCAL_ADS_SUCCESS';
export const LOCAL_ADS_FAIL = 'LOCAL_ADS_FAIL';

export const getAdsStart = () => {
    return {
        type: ADS_FETCH_START
    }
}

export const getAdsSuccess = (json) => {
    return {
        type: ADS_FETCH_SUCCESS,
        data: json
    }
}

export const getAdsFail = (error) => {
    return {
        type: ADS_FETCH_FAIL,
        error: error
    }
}

export const deleteAdStart = () => {
    return {
        type: DELETE_AD_START
    }
}

export const deleteAdSuccess = (json) => {
    return {
        type: DELETE_AD_SUCCESS,
        data: json
    }
}

export const deleteAdFail = (error) => {
    return {
        type: DELETE_AD_FAIL,
        error: error
    }
}

export const updateAdStart = () => {
    return {
        type: UPDATE_AD_START
    }
}

export const updateAdSuccess = (json) => {
    return {
        type: UPDATE_AD_SUCCESS,
        data: json
    }
}

export const updateAdFail = (error) => {
    return {
        type: UPDATE_AD_FAIL,
        error: error
    }
}

export const createAdStart = () => {
    return {
        type: CREATE_AD_START
    }
}

export const createAdSuccess = (json) => {
    return {
        type: CREATE_AD_SUCCESS,
        data: json
    }
}

export const createAdFail = (error) => {
    return {
        type: CREATE_AD_FAIL,
        error: error
    }
}

export const getAdStart = () => {
    return {
        type: AD_FETCH_START
    }
}

export const getAdSuccess = (json) => {
    return {
        type: AD_FETCH_SUCCESS,
        data: json
    }
}

export const getAdFail = (error) => {
    return {
        type: AD_FETCH_FAIL,
        error: error
    }
}

export const localAdsStart = () => {
    return {
        type: LOCAL_ADS_START
    }
}

export const localAdsSuccess = (json) => {
    return {
        type: LOCAL_ADS_SUCCESS,
        data: json
    }
}

export const localAdsFail = (error) => {
    return {
        type: LOCAL_ADS_FAIL,
        error: error
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchAds = (page, query) => {

    return function(dispatch) {
        dispatch(getAdsStart());
        return axiosInstance.get(`/ads/?page=${page}&search=${query}`)
            .then(res => {
                dispatch(getAdsSuccess(res.data));
            })
            .catch(error => {
                 toast.error('Something went wrong', options);
                dispatch(getAdsFail(error));
            })
    }
}

export const deleteAd = (id, page, search) => {

    return function(dispatch) {
        dispatch(deleteAdStart());
        return axiosInstance.delete(`/ads/${id}/`)
            .then(res => {
                toast.success('Ad deleted successfully', options);
                dispatch(deleteAdSuccess(id));
                dispatch(fetchAds(page, search));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(deleteAdFail(error));
            })
    }
}

export const updateAd = (id, data, page, search) => {

    return function(dispatch) {
        dispatch(updateAdStart());
        return axiosInstance.patch(`/ads/${id}/`, data)
            .then(res => {
                toast.success('Ad updated successfully', options);
                dispatch(updateAdSuccess(res.data));
                dispatch(fetchAds(page, search));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(updateAdFail(error));
            })
    }
}

export const createAd = (data, page, search) => {

    return function(dispatch) {
        dispatch(createAdStart());
        return axiosInstance.post(`/ads/`, data)
            .then(res => {
                toast.success('Ad created successfully', options);
                dispatch(createAdSuccess(res.data));
                dispatch(fetchAds(page, search));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(createAdFail(error));
            })
    }
}

export const fetchAd = (id) => {

    return function(dispatch) {
        dispatch(getAdStart());
        return axiosInstance.get(`/ads/${id}/`)
            .then(res => {
                dispatch(getAdSuccess(res.data));
            })
            .catch(error => {
                 toast.error('Something went wrong', options);
                dispatch(getAdFail(error));
            })
    }
}

export const fetchLocalAds = (id) => {

    return function(dispatch) {
        dispatch(localAdsStart());
        return axiosInstance.get(`/ads/local_ads/?location_id=${id}`)
            .then(res => {
                dispatch(localAdsSuccess(res.data));
            })
            .catch(error => {
                 toast.error('Something went wrong', options);
                dispatch(localAdsFail(error));
            })
    }
}