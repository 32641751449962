import React from 'react';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchSuggestions } from '../../redux/filters/actions';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import MobileAppBar from './MobileAppBar';
import { capitalize } from  '../../utils/capitalize';

import './home.css';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
  }
}));

const textStyle = {
	borderRadius: "50px",
	border: "0px",
	boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important",
	backgroundColor: "#fff",
	width: "290px"
};

const ZoneBanner = (props) => {
	let navigate = useNavigate();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [value, setValue] = React.useState('');
	const dispatch = useDispatch();

	const { isLoading, suggestions } = useSelector(state => {
        return {
            isLoading: state.filters.sugLoading,
            suggestions: state.filters.suggestions
        };
    });

	const showPopper = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearch = (e) => {
		setValue(e.target.value);
		if(anchorEl === null && e.target.value !== '')
			showPopper(e);
		else if(e.target.value === '')
			handleClose();
		props.handleSearchChange(e.target.value);
		dispatch(fetchSuggestions(e.target.value, props.location.name));	
	}

	const onSelectFilter = (name, status) => {
		setValue(capitalize(name));
		props.setFilterValue(name, status);
	}

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return(
		<div 
			className="hero-image" 
			style={{
				 backgroundImage: `url(${props.location.image})`
			}}
		>
			<Box display={{xs: "block", md: "none"}}>
		    	<MobileAppBar/>
		    </Box>
			<div className="back-btn">
				<Box display={{xs: "none", sm: "none", md: "block"}}>
					<Button onClick={() => navigate('/')} className="button rounded-btn" startIcon={<ArrowBackIcon />}>Back</Button>
				</Box>
			</div>
			<div className="hero-text">
				<h2 className="title">My Locality Info</h2>
				<h2>{capitalize(props.location.name)}</h2>
				<TextField 
					aria-describedby={id}
					autoComplete='off'
	        		className={classes.root}
	        		style={textStyle}
	        		onChange={handleSearch}
	        		value={value}
	        		placeholder="Search Names or Categories"
	        		InputProps={{
		            	startAdornment: ( <InputAdornment position="start">
		            			<SearchIcon fontSize="large"/> 
			            	</InputAdornment> 
			            )
		            }}
	        	/>
	        	<Popper
			        id={id}
			        open={open}
			        anchorEl={anchorEl}
			        onClose={handleClose}
			        onClick={handleClose}
			        placement="bottom-end"
			    >
			    	<Paper style={{width: '290px'}}>
			        	{isLoading ? (
			        		<div style={{textAlign: "center"}}>
			        			<CircularProgress/>
			        		</div>
			        		) : (<List
					     	sx={{ 
					     		width: '100%', 
					     		bgcolor: 'background.paper',
					     		overflow: 'auto',
        						maxHeight: 300,
					     	}}
					     	component="nav"
					     	aria-labelledby="nested-list-subheader"
					    >
						    <ListSubheader disableSticky={true}>Categories</ListSubheader>
						    {
						    	suggestions && suggestions.categories && suggestions.categories.map((categ) => (
						    		<ListItem disablePadding key={categ.id}>
							            <ListItemButton onClick={() => onSelectFilter(categ.name, "yes")}>
							              <ListItemText primary={capitalize(categ.name)} />
							            </ListItemButton>
						          	</ListItem>
						    	))
						    }
					    	{
						    	suggestions && suggestions.categories && 
						    	suggestions.categories.length === 0 && <div style={{textAlign:"center"}}>
						    		<p>No Categories</p></div>
						    }
						    <Divider/>
						    <ListSubheader disableSticky={true}>Services</ListSubheader>
						    {
						    	suggestions && suggestions.services && suggestions.services.map((service) => (
						    		<ListItem disablePadding key={service.id}>
							            <ListItemButton onClick={() => onSelectFilter(service.name, "no")}>
							              <ListItemText primary={capitalize(service.name)} />
							            </ListItemButton>
						          	</ListItem>
						    	))
						    }
					    	{
						    	suggestions && suggestions.services && 
						    	suggestions.services.length === 0 && <div style={{textAlign:"center"}}>
						    		<p>No Services</p></div>
						    }
					    </List>)
			        	}
			        </Paper>
			    </Popper>
			</div>
		</div>
	);
}

export default ZoneBanner;