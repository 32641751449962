import * as actions from './actions';

const initState = {
	counts: {},
	isLoading: false,
	error: null
}

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.DASHBOARD_FETCH_START:
            return {
            	...state,
            	isLoading: true
            };
        case actions.DASHBOARD_FETCH_SUCCESS:
            return {
            	...state,
            	isLoading: false,
            	counts: action.data,
            };
        case actions.DASHBOARD_FETCH_FAIL:
            return {
            	...state,
            	isLoading: false,
            	error: action.error
            };

        default:
            return state;
    }
}

export default dashboardReducer;