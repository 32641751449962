import * as actions from './actions';

const initState = {
    locations: [],
    location: {},
    error: null,
    isLoading: false,
    success: false
};

const getLocationsStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getLocationsSuccess = (state, action) => {
    return {
        ...state,
        locations: action.data,
        isLoading: false
    }
}

const getLocationsFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const deleteLocationStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const deleteLocationSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        locations: state.locations.filter(location => location.id !== action.data)
    }
}

const deleteLocationFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getLocationStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getLocationSuccess = (state, action) => {
    return {
        ...state,
        location: action.data,
        isLoading: false
    }
}

const getLocationFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const updateLocationStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const updateLocationSuccess = (state, action) => {
    return {
        ...state,
        locations: state.locations.map(loc => loc.id === action.data.id ?
            { ...loc, name: action.data.name } : 
            loc
        ),
        isLoading: false,
        success: true
    }
}

const updateLocationFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createLocationStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const createLocationSuccess = (state, action) => {
    return {
        ...state,
        locations: [...state.locations, action.data],
        isLoading: false,
        success: true
    }
}

const createLocationFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const locationClean = (state, action) => {
    return {
        ...state,
        category: {},
        success: false,
        error: null
    }
}

const locationReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.LOCATIONS_FETCH_START:
            return getLocationsStart(state, action);
        case actions.LOCATIONS_FETCH_SUCCESS:
            return getLocationsSuccess(state, action);
        case actions.LOCATIONS_FETCH_FAIL:
            return getLocationsFail(state, action);

        case actions.DELETE_LOCATION_START:
            return deleteLocationStart(state, action);
        case actions.DELETE_LOCATION_SUCCESS:
            return deleteLocationSuccess(state, action);
        case actions.DELETE_LOCATION_FAIL:
            return deleteLocationFail(state, action);

        case actions.LOCATION_FETCH_START:
            return getLocationStart(state, action);
        case actions.LOCATION_FETCH_SUCCESS:
            return getLocationSuccess(state, action);
        case actions.LOCATION_FETCH_FAIL:
            return getLocationFail(state, action);

        case actions.UPDATE_LOCATION_START:
            return updateLocationStart(state, action);
        case actions.UPDATE_LOCATION_SUCCESS:
            return updateLocationSuccess(state, action);
        case actions.UPDATE_LOCATION_FAIL:
            return updateLocationFail(state, action);

        case actions.CREATE_LOCATION_START:
            return createLocationStart(state, action);
        case actions.CREATE_LOCATION_SUCCESS:
            return createLocationSuccess(state, action);
        case actions.CREATE_LOCATION_FAIL:
            return createLocationFail(state, action);

        case actions.LOCATION_CLEAN:
            return locationClean(state, action);

        default:
            return state;
    }
}

export default locationReducer;