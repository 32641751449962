import * as actions from './actions';

const initState = {
    admins: [],
    error: null,
    isLoading: false,
    success: false,
    admin: {},
    profile: {}
};

const getAdminsStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getAdminsSuccess = (state, action) => {
    return {
        ...state,
        admins: action.data,
        isLoading: false
    }
}

const getAdminsFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createAdminStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const createAdminSuccess = (state, action) => {
    return {
        ...state,
        success: true,
        isLoading: false,
        admins: [...state.admins, action.data]
    }
}

const createAdminFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false
    }
}

const getAdminStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        admin: {}
    }
}

const getAdminSuccess = (state, action) => {
    return {
        ...state,
        admin: action.data,
        isLoading: false
    }
}

const getAdminFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const updateAdminStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const updateAdminSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        success: true,
        admins: state.admins.map(admin => admin.id === action.data.id ?
            { ...admin, name: action.data.name, 
                email: action.data.email, 
                location: action.data.location,
                mobile: action.data.mobile
            } : 
            admin
        ) 
    }
}

const updateAdminFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        success: false,
        isLoading: false,
    }
}

const deleteAdminStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
    }
}

const deleteAdminSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        admins: state.admins.filter(admin => admin.id !== action.data)
    }
}

const deleteAdminFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const getPofileStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        profile: {}
    }
}

const getPofileSuccess = (state, action) => {
    return {
        ...state,
        profile: action.data,
        isLoading: false
    }
}

const getPofileFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const updatePofileStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
    }
}

const updatePofileSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        profile: action.data
    }
}

const updatePofileFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.ADMINS_FETCH_START:
            return getAdminsStart(state, action);
        case actions.ADMINS_FETCH_SUCCESS:
            return getAdminsSuccess(state, action);
        case actions.ADMINS_FETCH_FAIL:
            return getAdminsFail(state, action);

        case actions.CREATE_ADMIN_START:
            return createAdminStart(state, action);
        case actions.CREATE_ADMIN_SUCCESS:
            return createAdminSuccess(state, action);
        case actions.CREATE_ADMIN_FAIL:
            return createAdminFail(state, action);

        case actions.GET_ADMIN_START:
            return getAdminStart(state, action);
        case actions.GET_ADMIN_SUCCESS:
            return getAdminSuccess(state, action);
        case actions.GET_ADMIN_FAIL:
            return getAdminFail(state, action);

        case actions.UPDATE_ADMIN_START:
            return updateAdminStart(state, action);
        case actions.UPDATE_ADMIN_SUCCESS:
            return updateAdminSuccess(state, action);
        case actions.UPDATE_ADMIN_FAIL:
            return updateAdminFail(state, action);

        case actions.DELETE_ADMIN_START:
            return deleteAdminStart(state, action);
        case actions.DELETE_ADMIN_SUCCESS:
            return deleteAdminSuccess(state, action);
        case actions.DELETE_ADMIN_FAIL:
            return deleteAdminFail(state, action);

        case actions.GET_PROFILE_START:
            return getPofileStart(state, action);
        case actions.GET_PROFILE_SUCCESS:
            return getPofileSuccess(state, action);
        case actions.GET_PROFILE_FAIL:
            return getPofileFail(state, action);

        case actions.UPDATE_PROFILE_START:
            return updatePofileStart(state, action);
        case actions.UPDATE_PROFILE_SUCCESS:
            return updatePofileSuccess(state, action);
        case actions.UPDATE_PROFILE_FAIL:
            return updatePofileFail(state, action);

        default:
            return state;
    }
}

export default adminReducer;