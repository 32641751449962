import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Footer() {
    return (
        <AppBar position="static" color="default" elevation={0} style={{textAlign: "center", bottom: "0"}}>
          <Container maxWidth="md">
              <Typography variant="body2" color="#474747">
                © 2022 My Locality Info
              </Typography>
          </Container>
        </AppBar>
    )
}
