import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MediaCard(props) {
    return (
    <Card sx={{ maxWidth: 345 }}>
        <CardMedia
            component="img"
            height="140"
            image={props.ad.image}
            alt="green iguana"
        />
        <CardContent>
            {props.ad.ad_type === 'small' ? <Typography gutterBottom variant="subtitle2" component="div">
                Location: {props.ad.location !== null ? props.ad.location : "None (Common ad)"}
            </Typography> : <Typography gutterBottom variant="subtitle2" component="div">
                Location: Common
            </Typography>}
            <Typography gutterBottom variant="subtitle2" component="div">
                Expiry: {props.ad.expiry}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
                Type: {props.ad.ad_type}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small" onClick={props.retrieveAd}>Edit</Button>
            <Button size="small" color="error" onClick={props.deleteAd}>Delete</Button>
        </CardActions>
        </Card>
    );
}