import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { OutlinedInput } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/auth/actions';
import './style.css';

import {
    oldPasswordValidation,
    newPasswordValidation
} from '../../utils/validation';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const PasswordChange = (props) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = React.useState({});
    const [visible_, setVisible_] = React.useState({});
    const [errorText, setErrorText] = React.useState({old_password: '', new_password: ''});

    const { changeSuccess, isLoading } = useSelector(data => {
        return {
            changeSuccess: data.auth.changeSuccess,
            isLoading: data.auth.isLoading
        };
    });

    const [state, setState] = React.useState({
        old_password: '',
        new_password: ''
    });

    React.useEffect(() => {
        if (changeSuccess === true){
            props.setOpen(false);
            setState({ old_password: '', new_password: ''});
            setErrorText({old_password: '', new_password: ''});
        }// eslint-disable-next-line
    },[changeSuccess]);

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const handleSubmit = () => {
        if (validateForm())
            dispatch(changePassword(state));
    }

    const validateForm = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = oldPasswordValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = newPasswordValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const handleClickShowPassword = () => {
        setVisible({
          ...visible,
          showPassword: !visible.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword_ = () => {
        setVisible_({
          ...visible_,
          showPassword_: !visible_.showPassword_,
        });
    };
    
    const handleMouseDownPassword_ = (event) => {
        event.preventDefault();
    };

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Change Password
                </Typography>

                <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>

                <OutlinedInput
                    id="old_password"
                    type={visible.showPassword ? 'text' : 'password'}
                    name='old_password'
                    fullWidth
                    onChange={handleChange}
                    size='small'
                    error={errorText.old_password}
                    helperText={errorText.old_password}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                                {visible.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <small style={{color: "red"}}>{errorText.old_password}</small>

                <InputLabel htmlFor="password" style={{marginTop: 10}}>New Password</InputLabel>

                <OutlinedInput
                    id="new_password"
                    type={visible_.showPassword_ ? 'text' : 'password'}
                    name='new_password'
                    fullWidth
                    onChange={handleChange}
                    size='small'
                    error={errorText.new_password}
                    helperText={errorText.new_password}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword_}
                            onMouseDown={handleMouseDownPassword_}
                            edge="end">
                                {visible_.showPassword_ ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <small style={{color: "red"}}>{errorText.new_password}</small><p></p>

                <Button 
                    onClick={handleSubmit} 
                    startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                    className='button'>
                        Submit
                </Button>
            </Box>
        </Modal>       
    );
}

export default PasswordChange;