import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const DASHBOARD_FETCH_START = 'DASHBOARD_FETCH_START';
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
export const DASHBOARD_FETCH_FAIL = 'DASHBOARD_FETCH_FAIL';

export const getDashboardStart = () => {
    return {
        type: DASHBOARD_FETCH_START
    }
}

export const getDashboardSuccess = (json) => {
    return {
        type: DASHBOARD_FETCH_SUCCESS,
        data: json
    }
}

export const getDashboardFail = (error) => {
    return {
        type: DASHBOARD_FETCH_FAIL,
        error: error
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};

export const fetchDashboard = (query) => {

    return function(dispatch) {
        dispatch(getDashboardStart());
        return axiosInstance.get(`/dashboard/`)
            .then(res => {
                dispatch(getDashboardSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getDashboardFail(error));
            })
    }
}