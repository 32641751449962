import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    appBarTransparent: {
        backgroundColor: 'transparent !important'
    },
    appBarSemiTransparent: {
        backgroundColor: 'rgba(255,255,255, 0.8) !important'
    },
    appBarSolid: {
        backgroundColor: '#fff !important'
    }
}));

const MobileAppBar = () => {
    const classes = useStyles();
    const navRef = React.useRef();
    let navigate = useNavigate();
    const [color, setColor] = useState('appBarTransparent');
    navRef.current = color;

     useEffect(() => {
        const handleScroll = () => {
            const solidShow = window.scrollY > 200;
            const semiShow = window.scrollY <= 200 && window.scrollY > 100;

            if (solidShow) {
                setColor('appBarSolid');
            } else if (semiShow) { 
                setColor('appBarSemiTransparent')
            } else {
                setColor('appBarTransparent');
            }
        }
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [])

    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar 
                position="fixed" 
                elevation={0}
                className={classes[navRef.current]}
            >
                <Toolbar>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  </Typography>
                  <Button onClick={() => navigate('/')} className="button rounded-btn" startIcon={<ArrowBackIcon />}>Back</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default MobileAppBar;