import React from 'react';

import Banner from '../components/home/Banner';
import AdsCarousel from '../components/ads/AdsCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { getHome } from '../redux/filters/actions';
import LocationCard from '../components/home/LocationCard';
import Slider from "react-slick";
import CircularProgress from '@mui/material/CircularProgress';

const LargeAds = ({home}) => {
	var settings = {
        dots: false,
        infinite: true,
        fade: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

	return (
		<Slider {...settings}>
            {
                home.large_ads && home.large_ads.map((ad) => (
                    <div key={ad.id} className="large-ad-div">
                        <img src={ad.image} alt="" className="large-ad-img"/>
                    </div>
                ))
            }
        </Slider>
	);
} 


const Home = () => {

	const { isLoading, home} = useSelector(state => {
        return {
            isLoading: state.filters.isLoading,
            home: state.filters.home,
        };
    });

	const dispatch = useDispatch();
	const [firstAds, setFirstAds] = React.useState([]);
	const [secondAds, setSecondAds] = React.useState([]);

	React.useEffect(() => {
		dispatch(getHome());
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if(home.ads !== []) {
			if(home.ads.length >= 6) {
				let firstHalf = home.ads.slice(0, Math.ceil(home.ads.length / 2));
				let secondHalf = home.ads.slice(firstHalf.length, home.ads.length);
				setFirstAds(firstHalf);
				setSecondAds(secondHalf);
			} else {
				setFirstAds(home.ads);
			}
		}
		// eslint-disable-next-line
	}, [home]);

	var settings = {
      dots: false,
      infinite: home.recent_locations.length >= 4,
      speed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
	};

	const loader = () => {
		return (
			<div style={{
				width: "100%", 
				height: "100vh", 
				textAlign: "center",
				marginTop: "80px"
			}}>
				<CircularProgress />
			</div>
		);
	}

	return(
		<>
			{/* <br/><br/> */}
			<Banner/><br/>

			{isLoading === true ? (loader()) : (
				<React.Fragment>
					<div style={{marginTop: "80px"}}>
				        <AdsCarousel ads={firstAds}/>
					</div>
					<div style={{ marginTop: "80px"}}>
						<p style={{paddingLeft: "37px"}}>Recently added Locations</p>
							 <Slider {...settings}>
								{
									home && home.recent_locations.map((loc) => (
										<LocationCard loc={loc} key={loc.id}/>
									))
								}
							</Slider>
					</div>

					{secondAds.length > 0 ? <div style={{marginTop: "80px"}}>
				        <AdsCarousel ads={secondAds}/>
					</div>: null}

					{home && home.large_ads && home.large_ads.length > 0 ? 
						<div style={{marginTop: "80px"}}>
							<LargeAds home={home}/>
						</div>: null}
					<br/><br/><br/>
				</React.Fragment>
			)
		}
		</>
	);
}

export default Home;