import React from 'react';
import {useDropzone} from 'react-dropzone';

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };
    
    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };
    
    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };
    
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };
    

const ImagePicker = (props) => {

    const [image, setImage] = React.useState(null);
    const [errorText, setErrorText] = React.useState('');

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'image/jpeg,image/png,image/jpg',
        onDrop: acceptedFiles => {
            acceptedFiles.map(file => {
                var isValid = resolutionValidator(file);
                if(isValid) return true;
                else return false;
            });
        }
    });

    const resolutionValidator = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        let flag = false;

        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < props.heights && width < props.widths) {
                    setErrorText(`minimum height=${props.heights} & width=${props.widths}`);
                    setImage(null);
                    flag = false;
                    return flag;
                } else {
                    setErrorText('');
                    setImage(Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }));
                    props.setImage(file);
                    flag = true;
                    return flag;
                }
            }
            return flag;
        };

        return flag;
    }

    const previewImage = () => {
        if (image !== null) {
            return (<div style={thumb} key={image.name}>
              <div style={thumbInner}>
                <img
                  alt='my_image'
                  src={image.preview}
                  style={img}
                />
              </div>
            </div>);
        } else if(props.api_image !== null) {
            return (
                <div style={thumb} key="prevv">
                  <div style={thumbInner}>
                    <img
                      alt='my_image'
                      src={props.api_image}
                      style={img}
                    />
                  </div>
                </div>
            );
        } else {
            return null;
        }
    }

    return(        
        <div {...getRootProps()} 
            style={{
                height: 120, 
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor:'grey',
                borderRadius: 10,
                padding: 10,
                marginBottom: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                <p>Drop the files here ...</p> :
                (<p style={{padding: 20}}>Drop or Select</p>)
            }
                <br/>
            <aside style={thumbsContainer}>
                {previewImage()}
            </aside>
            <small style={{color: "red"}}>{errorText}</small>
        </div>  
    );
}

export default ImagePicker;

        //   onDrop: acceptedFiles => {

        // }