import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { passwordReset } from '../../redux/auth/actions';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import './style.css';


import {
    password1Validation,
    password2Validation
} from '../../utils/validation';

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const {uid, token} = useParams();
    const [errorText, setErrorText] = React.useState({password1: '', password2: ''});

    const { isLoading } = useSelector(state => {
        return { isLoading: state.auth.isLoading };
    });

    const [values, setValues] = React.useState({
        password1: '',
        password2: '',
        showPassword1: false,
        showPassword2: false,
    });

    const options = {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
    };
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword1 = () => {
        setValues({
          ...values,
          showPassword1: !values.showPassword1,
        });
    };

    const handleClickShowPassword2 = () => {
        setValues({
          ...values,
          showPassword2: !values.showPassword2,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    
	const handleSubmit = () => {
        if (validateForm()){
            if(values.password1 === values.password2){
                dispatch(passwordReset({
                    new_password: values.password1,
                    uid: uid,
                    token: token
                }))
                .then(resetForm());			
            }
            else
                toast.error('Password does not match', options);
        }
	}

    const validateForm = () => {
        let fields = values;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = password1Validation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = password2Validation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const resetForm = () => {
        setValues({
            ...values,
            password1: '',
            password2: '',
            showPassword1: false,
            showPassword2: false,
        });
    } 

    return(
        <Box>
            <Typography variant='h5' style={{color: 'grey'}}>Change Password</Typography><br/>
          
            <InputLabel htmlFor="outlined-adornment-password">Enter New Password</InputLabel>
            <OutlinedInput
                id="password1"
                type={values.showPassword1 ? 'text' : 'password'}
                variant='outlined'
                value={values.password1}
                // style={{marginBottom: 10}}
                error={errorText.password1}
                helperText={errorText.password1}
                onChange={handleChange('password1')}
                size='small'
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {values.showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
            /><br/>
            <small style={{color: "red"}}>{errorText.password1}</small><br/>

            <InputLabel htmlFor="outlined-adornment-password">Re-Enter Password</InputLabel>
            <OutlinedInput
                id="password2"
                variant='outlined'
                type={values.showPassword2 ? 'text' : 'password'}
                value={values.password2}
                // style={{marginBottom: 10}}
                error={errorText.password2}
                helperText={errorText.password2}
                onChange={handleChange('password2')}
                size='small'
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {values.showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
            /><br/>
            <small style={{color: "red"}}>{errorText.password2}</small><br/>
                    
            <Button 
                className='button' 
                onClick={handleSubmit}
                disabled={isLoading}
                startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}>
                    Submit
            </Button>
        </Box>      
    );
}

export default ChangePassword;
