import axios from 'axios';
import history from './history';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
	timeout: 30000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'Bearer ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert('Connection timeout or server not responding');
			return Promise.reject(error);
		}

		if (error.response.status === 401 && originalRequest.url === '/accounts/refresh/') {
            localStorage.clear();
            axiosInstance.defaults.headers['Authorization'] = null;
			history.replace("/");
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/accounts/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;
							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							localStorage.clear();
							axiosInstance.defaults.headers['Authorization'] = null;
                    		history.replace("/");
						});
				} else {
					localStorage.clear();
					axiosInstance.defaults.headers['Authorization'] = null;
                    history.replace("/");
				}
			} else {
                localStorage.clear();
                axiosInstance.defaults.headers['Authorization'] = null;
				history.replace("/");
			}
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;