import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../redux/auth/actions';

const drawerWidth = 260;

const AdminAppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
	})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
		  easing: theme.transitions.easing.sharp,
		  duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	...(!open && {
		width: `calc(100% - 73px)`,
	}),
}));

const AppBar = (props) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
	    setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	    setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	let navigate = useNavigate();

	return (
		<AdminAppBar 
			elevation={0}
			position={props.position} 
			color="inherit"
			open={props.open}>
	        <Toolbar>
			<div style={{
				display: 'flex', 
				justifyContent: 'space-between', 
				width: '100%', 
				paddingTop: '10px'}}>

		        <Typography variant="h6" noWrap component="div">
					MyLocalityInfo
		        </Typography>
		        
				<div>
		              <IconButton
		              	aria-describedby={id}
		              	onClick={handleClick}
		                size="large"
		                aria-label="account of current user"
		                aria-controls="menu-appbar"
		                aria-haspopup="true"
		                color="inherit"
		              >
		                <PersonOutlineOutlinedIcon />
		              </IconButton>
		              <Popover
				        id={id}
				        open={open}
				        anchorEl={anchorEl}
				        onClose={handleClose}
				        anchorOrigin={{
				          vertical: 'bottom',
				          horizontal: 'left',
				        }}
				      >
				         <List>
				          <ListItem disablePadding>
				            <ListItemButton onClick={() => navigate('/admin/profile')}>
				              <ListItemIcon>
				                <AccountCircleIcon/>
				              </ListItemIcon>
				              <ListItemText primary="Profile" />
				            </ListItemButton>
				          </ListItem>

				          <ListItem disablePadding>
				            <ListItemButton onClick={() => dispatch(userLogout())}>
				              <ListItemIcon>
				                <LogoutIcon/>
				              </ListItemIcon>
				              <ListItemText primary="Logout" />
				            </ListItemButton>
				          </ListItem>
				        </List>
				      </Popover>
					</div>
            	</div>
	        </Toolbar>
     	</AdminAppBar>
	);
}

export default AppBar;