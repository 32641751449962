import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const DrawerMiniCard = (props) => {
	return (
		<Grid container style={{marginLeft: "15px"}}>
        	<Box sx={{
        		height: '70px', 
        		width: '100%',
        		backgroundColor: "#f2f2f2",
        		borderRadius: '15px',
        		marginBottom: "15px",
        		marginRight: "30px"
        	}}>
        		<Stack direction="row" spacing={2} 
        			style={{
					    alignItems: "center",
					    marginTop: "9px",
					    marginLeft: "15px"}}>
					{ props.image === '' ? 
						<Avatar style={{backgroundColor: 'blueviolet'}}>{props.title.charAt(0)}</Avatar> :
						<Avatar src={process.env.REACT_APP_API_URL + props.image}/>
					}
        			<Stack direction="column" spacing={0}>
        				<Typography variant="subtitle1" color="#637381">{props.title}</Typography>
        				<Typography variant="subtitle2" color="#969696">{props.sub === 'super_user' ? 'Super Admin' : 'Admin'}</Typography>
        			</Stack>
        		</Stack>
        	</Box>
	    </Grid>
	);
}

export default DrawerMiniCard;