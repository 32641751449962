import * as actions from './actions';

const initState = {
    services: {},
    error: null,
    isLoading: false,
    success: false,
    service: {}
};

const getServicesStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getServicesSuccess = (state, action) => {
    return {
        ...state,
        services: action.data,
        isLoading: false
    }
}

const getServicesFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}


const createServiceStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const createServiceSuccess = (state, action) => {
    return {
        ...state,
        success: true,
        isLoading: false
    }
}

const createServiceFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false
    }
}

const getServiceStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        service: {}
    }
}

const getServiceSuccess = (state, action) => {
    return {
        ...state,
        service: action.data,
        isLoading: false
    }
}

const getServiceFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        service: {}
    }
}

const updateServiceStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const updateServiceSuccess = (state, action) => {
    return {
        ...state,
        success: true,
        isLoading: false
    }
}

const updateServiceFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false
    }
}

const deleteServiceStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
    }
}

const deleteServiceSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
    }
}

const deleteServiceFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const serviceReducer = (state = initState, action) => {

    switch (action.type) {
        case actions.SERVICES_FETCH_START:
            return getServicesStart(state, action);
        case actions.SERVICES_FETCH_SUCCESS:
            return getServicesSuccess(state, action);
        case actions.SERVICES_FETCH_FAIL:
            return getServicesFail(state, action);

        case actions.CREATE_SERVICE_START:
            return createServiceStart(state, action);
        case actions.CREATE_SERVICE_SUCCESS:
            return createServiceSuccess(state, action);
        case actions.CREATE_SERVICE_FAIL:
            return createServiceFail(state, action);
        
        case actions.GET_SERVICE_START:
            return getServiceStart(state, action);
        case actions.GET_SERVICE_SUCCESS:
            return getServiceSuccess(state, action);
        case actions.GET_SERVICE_FAIL:
            return getServiceFail(state, action);

        case actions.UPDATE_SERVICE_START:
            return updateServiceStart(state, action);
        case actions.UPDATE_SERVICE_SUCCESS:
            return updateServiceSuccess(state, action);
        case actions.UPDATE_SERVICE_FAIL:
            return updateServiceFail(state, action);
        
        case actions.DELETE_SERVICE_START:
            return deleteServiceStart(state, action);
        case actions.DELETE_SERVICE_SUCCESS:
            return deleteServiceSuccess(state, action);
        case actions.DELETE_SERVICE_FAIL:
            return deleteServiceFail(state, action);

        default:
            return state;
    }
}

export default serviceReducer;
