import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from '../assets/images/logo.svg';

const AuthLayout = () => {
	return (JSON.parse(localStorage.getItem("logged_in")) !== true) ? (
        <Grid container >
            <Grid item sm={6} md={6} lg={6} xl={6} display={{xs:'none', md:'block'}}>
                <Box  style={{ 
                    width: '100%', 
                    height: '100vh', 
                    backgroundColor: '#f2f2f2', 
                    borderRadius: '0px 60px 60px 0px', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <img src={logo} alt="" style={{width: "350px", height: "250px"}}/>
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} xl={6} lg={6} md={6} 
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', 
                    height: '100vh'
                }}>
        
                <main>
                    <Outlet/>
                </main>
            </Grid>
        </Grid>
	) : <Navigate to="/admin"/>;
}

export default AuthLayout;
