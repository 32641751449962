import * as actions from './actions';

const initState = {
    ads: {},
    ad: {},
    error: null,
    isLoading: false,
    success: false,
    localAds: []
};

const getAdsStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getAdsSuccess = (state, action) => {
    return {
        ...state,
        ads: action.data,
        isLoading: false
    }
}

const getAdsFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const deleteAdStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const deleteAdSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false
    }
}

const deleteAdFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getAdStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getAdSuccess = (state, action) => {
    return {
        ...state,
        ad: action.data,
        isLoading: false
    }
}

const getAdFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const updateAdStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const updateAdSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        success: true
    }
}

const updateAdFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false
    }
}

const createAdStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const createAdSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        success: true
    }
}

const createAdFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        success: false
    }
}

const localStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const localSuccess = (state, action) => {
    return {
        ...state,
        localAds: action.data,
        isLoading: false
    }
}

const localFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const adReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.ADS_FETCH_START:
            return getAdsStart(state, action);
        case actions.ADS_FETCH_SUCCESS:
            return getAdsSuccess(state, action);
        case actions.ADS_FETCH_FAIL:
            return getAdsFail(state, action);

        case actions.DELETE_AD_START:
            return deleteAdStart(state, action);
        case actions.DELETE_AD_SUCCESS:
            return deleteAdSuccess(state, action);
        case actions.DELETE_AD_FAIL:
            return deleteAdFail(state, action);

        case actions.AD_FETCH_START:
            return getAdStart(state, action);
        case actions.AD_FETCH_SUCCESS:
            return getAdSuccess(state, action);
        case actions.AD_FETCH_FAIL:
            return getAdFail(state, action);

        case actions.UPDATE_AD_START:
            return updateAdStart(state, action);
        case actions.UPDATE_AD_SUCCESS:
            return updateAdSuccess(state, action);
        case actions.UPDATE_AD_FAIL:
            return updateAdFail(state, action);

        case actions.CREATE_AD_START:
            return createAdStart(state, action);
        case actions.CREATE_AD_SUCCESS:
            return createAdSuccess(state, action);
        case actions.CREATE_AD_FAIL:
            return createAdFail(state, action);

        case actions.LOCAL_ADS_START:
            return localStart(state, action);
        case actions.LOCAL_ADS_SUCCESS:
            return localSuccess(state, action);
        case actions.LOCAL_ADS_FAIL:
            return localFail(state, action);

        default:
            return state;
    }
}

export default adReducer;