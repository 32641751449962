import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

export const SERVICES_FETCH_START = 'SERVICES_FETCH_START';
export const SERVICES_FETCH_SUCCESS = 'SERVICES_FETCH_SUCCESS';
export const SERVICES_FETCH_FAIL = 'SERVICES_FETCH_FAIL';

export const CREATE_SERVICE_START = 'CREATE_SERVICE_START';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL';

export const GET_SERVICE_START = 'GET_SERVICE_START';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAIL = 'GET_SERVICE_FAIL';

export const UPDATE_SERVICE_START = 'UPDATE_SERVICE_START';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';

export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';

export const getServicesStart = () => {
    return {
        type: SERVICES_FETCH_START
    }
}

export const getServicesSuccess = (json) => {
    return {
        type: SERVICES_FETCH_SUCCESS,
        data: json
    }
}

export const getServicesFail = (error) => {
    return {
        type: SERVICES_FETCH_FAIL,
        error: error
    }
}

export const createServiceStart = () => {
    return {
        type: CREATE_SERVICE_START
    }
}

export const createServiceSuccess = (json) => {
    return {
        type: CREATE_SERVICE_SUCCESS,
        data: json
    }
}

export const createServiceFail = (error) => {
    return {
        type: CREATE_SERVICE_FAIL,
        error: error
    }
}

export const getServiceStart = () => {
    return {
        type: GET_SERVICE_START
    }
}

export const getServiceSuccess = (json) => {
    return {
        type: GET_SERVICE_SUCCESS,
        data: json
    }
}

export const getServiceFail = (error) => {
    return {
        type: GET_SERVICE_FAIL,
        error: error
    }
}

export const updateServiceStart = () => {
    return {
        type: UPDATE_SERVICE_START
    }
}

export const updateServiceSuccess = (json) => {
    return {
        type: UPDATE_SERVICE_SUCCESS,
        data: json
    }
}

export const updateServiceFail = (error) => {
    return {
        type: UPDATE_SERVICE_FAIL,
        error: error
    }
}

export const deleteServiceStart = () => {
    return {
        type: DELETE_SERVICE_START
    }
}

export const deleteServiceSuccess = (json) => {
    return {
        type: DELETE_SERVICE_SUCCESS,
        data: json
    }
}

export const deleteServiceFail = (error) => {
    return {
        type: DELETE_SERVICE_FAIL,
        error: error
    }
}

const options = {
    position: "top-right",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
};


export const fetchServices = (query, page) => {

    return function(dispatch) {
        dispatch(getServicesStart());
        return axiosInstance.get(`/services/?search=${query}&page=${page}`)
            .then(res => {
                dispatch(getServicesSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getServicesFail(error));
            })
    }
}

export const createService = (data, query, page) => {

    return function(dispatch) {
        dispatch(createServiceStart());
        return axiosInstance.post(`/services/`, data)
            .then(res => {
                toast.success('Service created successfully', options);
                dispatch(createServiceSuccess(res.data));
                dispatch(fetchServices(query, page));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(createServiceFail(error));
            })
    }
}

export const getService = (id) => {

    return function(dispatch) {
        dispatch(getServiceStart());
        return axiosInstance.get(`/services/${id}/`)
            .then(res => {
                dispatch(getServiceSuccess(res.data));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(getServiceFail(error));
            })
    }
}

export const updateService = (id, data, query, page) => {

    return function(dispatch) {
        dispatch(updateServiceStart());
        return axiosInstance.patch(`/services/${id}/`, data)
            .then(res => {
                toast.success('Service updated successfully', options);
                dispatch(updateServiceSuccess(res.data));
                dispatch(fetchServices(query, page));
            })
            .catch(error => {
                try{
                    if (error && error.response && error.response.status === 400) {
                        if(error.response.data && error.response.data.image && error.response.data.image[0])
                            toast.error(error.response.data.image[0], options);
                    } else 
                        toast.error('Something went wrong', options);
                } catch(err) {
                    toast.error('Something went wrong', options);
                }
                dispatch(updateServiceFail(error));
            })
    }
}

export const deleteService = (id, query, page) => {

    return function(dispatch) {
        dispatch(deleteServiceStart());
        return axiosInstance.delete(`/services/${id}/`)
            .then(res => {
                toast.success('Service deleted successfully', options);
                dispatch(deleteServiceSuccess(id));
                dispatch(fetchServices(query, page));
            })
            .catch(error => {
                toast.error('Something went wrong', options);
                dispatch(deleteServiceFail(error));
            })
    }
}