import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations } from '../../redux/location/actions';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
// import Container from '@mui/material/Container';

// import bannerImg from '../../assets/images/banner.svg';
import SearchBar from '../search/SearchBar';
import './home.css';


const Banner = () => {
	const dispatch = useDispatch();
	let navigate = useNavigate();

    const { locations } = useSelector(state => {
        return {
            locations: state.location.locations
        };
    });

	const handleSearch = (e) => {
		dispatch(fetchLocations(e.target.value));
	}

	const handleSelect = (value) => {
		if(!isNaN(Number(value.id)))
			navigate(`${value.id}/${value.name}`);
	}

	return (
		<div className="banner-wrapper">
			<Grid container spcaing={4}>
				<Grid item sm={12} md={3}
					display={{ xs: "none", md: "block" }}
				>
					{/* <img className="banner-img" src={bannerImg} alt=""/> */}
				</Grid>
				<Grid item sm={12} md={6} lg={6}
					style={{marginTop: "140px", padding:"25px", height: '80vh', textAlign:"center"}}
					display={{ xs: "none", md: "block" }}
				>
					<p className="title desktop">My Locality Info</p>
					<p className="title2 desktop2">Find your solutions quickly</p>
					<p className="subtitle desktop">
						Get your work done quickly and easily. 
						MyLocalityInfo is a digital service provider that helps you in finding solutions 
						to a variety of needs in your local area. 
						Also, register your business with us and let people discover you.
					</p>
					<SearchBar 
						list={locations}
						handleSearch={handleSearch}
						handleSelect={handleSelect}
					/>
				</Grid>
				<Grid item sm={12} md={3}
					display={{ xs: "none", md: "block" }}
				>
					{/* <img className="banner-img" src={bannerImg} alt=""/> */}
				</Grid>
				<Grid item sm={12}
					style={{textAlign:"center", padding: '10px', marginTop: '60px', height: '60vh'}}
					display={{ lg: "none", md: "none", xs: "block" }}
				>
					<p className="title mobile">My Locality Info</p>
					<p className="title2 mobile">Find your solutions quickly</p>
					<p className="subtitle mobile">
						Get your work done quickly and easily. 
						MyLocalityInfo is a digital service provider that helps you in finding solutions 
						to a variety of needs in your local area. 
						Also, register your business with us and let people discover you.
					</p>
					<br/>
					<SearchBar 
						list={locations}
						handleSearch={handleSearch}
						handleSelect={handleSelect}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default Banner;