import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import ImagePicker from './ImagePicker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector} from 'react-redux';
import {createService, updateService } from '../../redux/service/actions';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

import {
    nameValidation,
    addressValidation,
    categoryValidation,
    adminValidation,
    contactValidation,
    imageValidation
} from '../../utils/validation';

const style = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const tempValue = {
    id: null,
    name: 'select'
};

const temp ={
    id: null,
    email: '',
    is_admin: true,
    location: '',
    mobile: '',
    name: 'select',
    profile_pic: null,
    created_at: '',
}

const ServiceForm = (props) => {

    const dispatch = useDispatch();
    const [blobImg, setBlobImg] = React.useState(null);
    const [user, setUser] = React.useState({user_role : '' , user_id : null});

    const [state, setState] = React.useState({
        id: null, 
        name: '',
        category: null,
        address: '',
        landmark: '',
        image: null,
        contact: '',
        admin: null
    });

    const [errorText, setErrorText] = React.useState({
        name: '',
        category: '',
        address: '',
        landmark: '',
        image: '',
        contact: '',
        admin: ''
    });

    const { loading } = useSelector(state => {
        return { loading: state.service.isLoading };
    });
     
	React.useEffect(() => {
        getBasicInfo();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props.service !== {}){
            setState({...state,
                id:props.service.id, 
                name: props.service.name,
                category: props.service.category,
                address: props.service.address,
                landmark: props.service.landmark,
                image: props.service.image,
                contact: props.service.contact,
                admin: props.service.admin
            });
            setBlobImg(null);
        }// eslint-disable-next-line
    },[props.service]);

    React.useEffect(() => {
        if (props.newe === true){
            resetForm();
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.newe]);

    React.useEffect(() => {
        if (props.open === false){
            resetForm();
            setBlobImg(null);
        }
        // eslint-disable-next-line
    },[props.open]);

    const handleCategory = (e) => {
        setState({...state, category: e});
    }

    const handleAdmin = (e) => {
        setState({...state, admin: e});
    }
    
    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const setImage = (imageBlob) => {
        setState({...state, image: "set"});
        setBlobImg(imageBlob);
    }

    const appendFormData = () => {
        let formData = new FormData();

        formData.append("name", state.name);

        formData.append("address", state.address);

        formData.append("landmark", state.landmark);

        formData.append("contact", state.contact);

        if(state.category !== null && !isNaN(Number(state.category.id)))
            formData.append("category", state.category.id);

        if(user.user_role === 'super_user'){
            if(state.admin !== null && !isNaN(Number(state.admin.id)))
                formData.append("admin", state.admin.id);
        }
        else{
            if(user !== null && !isNaN(Number(user.user_id)))
                formData.append("admin", user.user_id);
        }   
        if(blobImg !== null)
            formData.append("image", blobImg);
        
        return formData;
    }

    const submitForm = () => {
        if (validateForm()){
            let fd = appendFormData();
            if (state.id === null){
                dispatch(createService(fd, '', props.rowsState.page + 1));
            }
            else
                dispatch(updateService(state.id, fd, props.search, props.rowsState.page + 1));
        }
    }

    const validateForm = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = nameValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = categoryValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = addressValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        if(user.user_role === 'super_user'){
            validationDetails = adminValidation(fields, errors, formIsValid);
            formIsValid = setValidationFlag(validationDetails);
        }
        validationDetails = contactValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = imageValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }

    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const resetForm = () => {
        setState({...state,
            id: null, 
            name: '',
            category: null,
            address: '',
            landmark: '',
            image: null,
            contact: '',
            admin: null
        });
        setErrorText({...errorText,
            name: '',
            category: '',
            address: '',
            landmark: '',
            image: '',
            contact: '',
            admin: ''
        });
    }

	const getBasicInfo = () => {
		setUser({...user, 
            user_role : localStorage.getItem('user_role'),
            user_id : localStorage.getItem('user_id'),
		})
	}

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            style={{overflow:'scroll'}}
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Add Service
                </Typography>

                <InputLabel htmlFor="name">Name</InputLabel>
                <TextField
                    id="name"
                    name="name"
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={state.name}
                    error={errorText.name}
                    helperText={errorText.name}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />

                <InputLabel htmlFor="category">Category</InputLabel>
                <Autocomplete
                    disableClearable
                    disablePortal
                    id="category"
                    fullWidth
                    size='small'
                    style={{marginBottom: 10}}
                    options={props.categories}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={(state && state.category) ?? tempValue }
                    onChange={(event, newValue) => handleCategory(newValue)}
                    renderInput={(params) => 
                        <TextField 
                            variant="outlined" {...params} 
                            error={errorText.category}
                            helperText={errorText.category}
                        />}
                />

                <InputLabel htmlFor="address">Address</InputLabel>
                <TextField
                    id="address"
                    name="address"
                    size='small'
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    value={state.address}
                    error={errorText.address}
                    helperText={errorText.address}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />

                <InputLabel htmlFor="landmark">Landmark</InputLabel>
                <TextField
                    id="landmark"
                    name="landmark"
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={state.landmark}
                    error={errorText.landmark}
                    helperText={errorText.landmark}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />
                
                <InputLabel htmlFor="image">Image</InputLabel>
                <ImagePicker setImage={setImage} api_image={state.image} widths={400} heights={400}/>
                <small style={{color: "red"}}>{errorText.image}</small>

                <InputLabel htmlFor="contact">Contact Number</InputLabel>
                <TextField
                    id="contact"
                    className='input'
                    name="contact"
                    type='number'
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={state.contact}
                    error={errorText.contact}
                    helperText={errorText.contact}
                    onChange={handleChange}
                    style={{marginBottom: 10}}
                />

                {user.user_role && user.user_role === 'super_user' ?
                <div>
                <InputLabel htmlFor="admin">Admin</InputLabel>
                    <Autocomplete
                        disableClearable
                        disablePortal
                        id="admin"
                        fullWidth
                        size='small'
                        style={{marginBottom: 10}}
                        options={props.admins}
                        getOptionLabel={option => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={(state && state.admin) ?? temp }
                        onChange={(event, newValue) => handleAdmin(newValue)}
                        renderInput={(params) => 
                            <TextField 
                                variant="outlined" {...params} 
                                error={errorText.admin}
                                helperText={errorText.admin}    
                            />}
                    />
                </div> : null }

                <Button 
                    onClick={submitForm}
                    disabled={loading}
                    startIcon={loading === true ? <CircularProgress size={20} color="inherit"/> : null} 
                    className='button'>
                        Submit
                </Button>
            </Box>
        </Modal>       
    );
}

export default ServiceForm;