import * as actions from './actions';

const initState = {
    categories: [],
    error: null,
    isLoading: false,
    success: false,
    category: {}
};

const getCategoriesStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const getCategoriesSuccess = (state, action) => {
    return {
        ...state,
        categories: action.data,
        isLoading: false
    }
}

const getCategoriesFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createCategoryStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const createCategorySuccess = (state, action) => {
    return {
        ...state,
        success: true,
        isLoading: false,
        categories: [...state.categories, action.data]
    }
}

const createCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getCategoryStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        category: {}
    }
}

const getCategorySuccess = (state, action) => {
    return {
        ...state,
        category: action.data,
        isLoading: false
    }
}

const getCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        category: {}
    }
}

const updateCategoryStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        success: false
    }
}

const updateCategorySuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        success: true,
        categories: state.categories.map(categ => categ.id === action.data.id ?
            { ...categ, name: action.data.name } : 
            categ
        ) 
    }
}

const updateCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const deleteCategoryStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const deleteCategorySuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        categories: state.categories.filter(category => category.id !== action.data)
    }
}

const deleteCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const categoryClean = (state, action) => {
    return {
        ...state,
        category: {},
        success: false,
        error: null
    }
}

const categoryReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.CATEGORIES_FETCH_START:
            return getCategoriesStart(state, action);
        case actions.CATEGORIES_FETCH_SUCCESS:
            return getCategoriesSuccess(state, action);
        case actions.CATEGORIES_FETCH_FAIL:
            return getCategoriesFail(state, action);

        case actions.CREATE_CATEGORY_START:
            return createCategoryStart(state, action);
        case actions.CREATE_CATEGORY_SUCCESS:
            return createCategorySuccess(state, action);
        case actions.CREATE_CATEGORY_FAIL:
            return createCategoryFail(state, action);

        case actions.GET_CATEGORY_START:
            return getCategoryStart(state, action);
        case actions.GET_CATEGORY_SUCCESS:
            return getCategorySuccess(state, action);
        case actions.GET_CATEGORY_FAIL:
            return getCategoryFail(state, action);

        case actions.UPDATE_CATEGORY_START:
            return updateCategoryStart(state, action);
        case actions.UPDATE_CATEGORY_SUCCESS:
            return updateCategorySuccess(state, action);
        case actions.UPDATE_CATEGORY_FAIL:
            return updateCategoryFail(state, action);

        case actions.DELETE_CATEGORY_START:
            return deleteCategoryStart(state, action);
        case actions.DELETE_CATEGORY_SUCCESS:
            return deleteCategorySuccess(state, action);
        case actions.DELETE_CATEGORY_FAIL:
            return deleteCategoryFail(state, action);

        case actions.CATEGORY_CLEAN:
            return categoryClean(state, action);

        default:
            return state;
    }
}

export default categoryReducer;