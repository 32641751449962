import React from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#fdfdff'
    },
    pageHeader:{
        padding:'30px',
        display:'flex',
        marginBottom:'16px'
    },
    pageIcon:{
        display:'inline-block',
        padding:'10px',
        color:'#3c44b1'
    },
    pageTitle:{
        paddingLeft:'32px',
        '& .MuiTypography-subtitle2':{
            opacity:'0.6'
        }
    }
});


const PageHeader = (props) => {

    const classes = useStyles();
    const { title, subTitle, icon } = props;

    return (
        <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
                <div className={classes.pageIcon}>
                    {icon}
                </div>
                <div className={classes.pageTitle}>
                    <Typography
                        variant="h6"
                        component="div">
                        {title}</Typography>
                    <Typography
                        variant="subtitle2"
                        component="div">
                        {subTitle}</Typography>
                </div>
            </div>
        </Paper>
    )
}

export default PageHeader;