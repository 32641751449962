import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCategory, categoryClean } from '../../redux/category/actions';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    p: 4,
};

const CategoryForm = (props) => {
    const [categ, setCateg] = React.useState({id: null, name: ""});
    const [errorText, setErrorText] = React.useState('');

    const dispatch = useDispatch();
    
    const { isLoading } = useSelector(state => {
        return {
            isLoading: state.category.isLoading
        };
    });

    React.useEffect(() => {
        if (props.category !== {} && props.category.id !== undefined) {
            setErrorText('');
            setCateg({...categ, id: props.category.id, name: props.category.name});
        }
        // eslint-disable-next-line
    }, [props.category]);

    React.useEffect(() => {
        if (props.newe === true){
            setErrorText('');
        }
        // eslint-disable-next-line
    },[props.newe]);

    React.useEffect(() => {
        if (props.open === false){
            dispatch(categoryClean());
            setCateg({...categ, id: null, name: ''});
            setErrorText('');
        }
        // eslint-disable-next-line
    },[props.open]);

    const handleChange = (e) => {
        setCateg({...categ, name: e.target.value});
    }

    const submitForm = () => {
        if(categ.name === '') 
            setErrorText("Category is mandatory");
        else if(categ.name.length < 3) 
            setErrorText("Need atlest 3 characters");
        else {
           if(categ.id === null || categ.id === undefined) {
                dispatch(createCategory(categ))
            } else {
                dispatch(updateCategory(categ.id, categ));
            }
        }
    }

    return(        
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: 10, color: 'grey'}}>
                    Add Category
                </Typography>

                <InputLabel htmlFor="outlined-adornment-password">Category Name</InputLabel>

                <TextField
                    id="outlined-basic"
                    size='small'
                    fullWidth
                    variant="outlined"
                    style={{marginBottom: 10}}
                    name="name"
                    onChange={handleChange}
                    value={categ.name}
                    error={errorText !== ''}
                    helperText={errorText}
                />

                <Button 
                    onClick={submitForm} 
                    className='button'
                    disabled={isLoading}
                    startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                >
                    Submit
                </Button>
            </Box>
        </Modal>       
    );
}

export default CategoryForm;