import React from 'react';
import { useNavigate } from 'react-router-dom';

const LocationCard = (props) => {
	let navigate = useNavigate();

	const handleNavigate = () => {
		if (props.loc.id !== null)
			navigate(`${props.loc.id}/${props.loc.name}`);
	}

	return(
 		<div className="loc-card-wrapper" onClick={e => handleNavigate()}>
        	<img src={props.loc.image} alt=" " height="200px" width="100%"/>
	        <h2 className="second-txt">
	            {props.loc.name.toUpperCase()}
	        </h2>
    	</div>
	);
}

export default LocationCard;