import React from 'react';
import PageHeader from '../../components/admin/PageHeader';
import { makeStyles } from '@mui/styles';
import { Paper, TextField, Button, Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DataGrid } from '@mui/x-data-grid';
import LocationForm from '../../components/forms/LocationForm';
import ConfirmDialog from '../../components/forms/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, deleteLocation, 
         fetchLocation } from '../../redux/location/actions';
import { capitalize } from  '../../utils/capitalize';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: '10px',
        padding: '10px',
        "@media (min-width: 1280px)": {
          margin: "0px"        
      },
    },
    searchInput: {
        width: '75%'
    }
}));


const Location = () => {

    const columns = [
        { 
            field: 'name', 
            headerName: 'Location',
            flex: 0.5,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <p>{capitalize(cellValues.value)}</p>
                );
            }
        },
        { 
            field: 'actions', 
            headerName: 'Actions', 
            align: "right",
            sortable: false,
            renderCell: (cellValues) => {
            return (
                <>
                    <IconButton color="primary" aria-label="upload picture" 
                    component="span" onClick={() => getDetail(cellValues.id)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="error" aria-label="upload picture" 
                        component="span" onClick={() => openDialog(cellValues.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            );
          }
        }
    ];

    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);   
    const [newe, setNew] = React.useState(false); 
    const [dialog, setDialog] = React.useState(false);
    const [delId, setDelId] = React.useState();

    const { isLoading, locations, location, success } = useSelector(state => {
        return {
            isLoading: state.location.isLoading,
            locations: state.location.locations,
            location: state.location.location,
            success: state.location.success
        };
    });

    React.useEffect(() => {
        dispatch(fetchLocations(''));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if(success) handleClose();
        // eslint-disable-next-line
    }, [success]);

    const handleSearch = (e) => {
        dispatch(fetchLocations(e.target.value));
    }

    const getDetail = (id) => {
        setNew(false);
        setOpen(true);
        dispatch(fetchLocation(id));
    }

    const addNew = () => {
        setNew(true);
        setOpen(true);
    }

    const handleClose = () => {
        setNew(true);
        setOpen(false);
    }

    const openDialog = (id) => {
        setDelId(id);
        setDialog(true);
    }

    const closeDialog = () => {
        setDialog(false);
    }

    const handleDelete = () => {
        dispatch(deleteLocation(delId));
        setDialog(false);
    }

    return (
        <div id="datatable">
           <PageHeader
                title="Locations"
                subTitle="Add, Edit Delete Locations"
                icon={<LocationOnIcon fontSize="large" />}
            />

            <Paper className={classes.pageContent}>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            name="search"
                            size="small"
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row-reverse">      
                            <Button variant="outlined" size="large" onClick={addNew}>Add New</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={locations}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        autoHeight={true}
                        loading={isLoading}
                    />
                </div>
            </Paper>

            <LocationForm
                open={open}
                handleClose={handleClose}
                location={location}
                newe={newe}
            /> 

            <ConfirmDialog
                open={dialog}
                handleClose={closeDialog}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default Location;