import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import './search.css';
import { capitalize } from  '../../utils/capitalize';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
  }
}));

const SearchBar = (props) => {
	const classes = useStyles();

	return (
		<div className="search-wrapper">
		    <Autocomplete
		        id="free-solo-demo"
		        freeSolo
		        options={props.list}
		        getOptionLabel={option => capitalize(option.name)}
						isOptionEqualToValue={(option, value) => option.id === value.id}
		        PaperComponent={({ children }) => (
		          <Paper 
		          		style={{ 
		          			boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
		          			borderRadius: "20px",
							backgroundColor: 'white'
		          		}}
		          	>
		          		{children}
		          	</Paper>
		        )}
		        onChange={(event, newValue) => props.handleSelect(newValue)}
		        renderInput={(params) => 
		        	<TextField 
		        		{...params} 
		        		onChange={props.handleSearch}
		        		className={classes.root}
		        		style={{
		        			borderRadius: "50px",
		        			border: "0px",
		        			boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
							backgroundColor: 'white'
		        		}}
		        		placeholder="Search Places"
		        		InputProps={{
			            	...params.InputProps,
			            	startAdornment: ( <InputAdornment position="start">
			            			<SearchIcon fontSize="large"/> 
				            	</InputAdornment> 
				            )
			            }}
		        	/>
		        }
      		/>
      	</div>
	);
}

export default SearchBar;