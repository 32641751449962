import React from 'react';
import image from '../assets/images/not_found.svg';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	let navigate = useNavigate();

	const goToHome = () => {
		navigate("/");
	}

	const goToSigIn = () => {
		navigate("auth");
	}

	return(
		<div style={{
			textAlign:"center",
		    position: 'absolute',
		    top: '50%',
		    left: '50%',
		    transform: 'translate(-50%, -50%)'
		}}>
			<img
				style={{
					width: "100%",
					maxWidth: '500px',
				}} 
				src={image} 
				alt="notfound"
			/>
			<br/>
			<div style={{width: "100%", maxWidth: "500px"}}>
				<Button variant="text" onClick={goToHome}>Home</Button>&nbsp;
				<Button variant="text" onClick={goToSigIn} color="warning">Signin</Button>
			</div>
		</div>
	);
}

export default NotFound;