import { Box } from '@mui/system';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Grid, Typography, Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ImagePicker from '../../components/forms/ImagePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateProfile} from '../../redux/admin/actions';
import { mobileValidation, nameValidation } from '../../utils/validation';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordChange from '../../components/forms/PasswordChange';
import Link from '@mui/material/Link';
import './style.css';


const Profile = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [blobImg, setBlobImg] = React.useState(null);
    const [errorText, setErrorText] = React.useState({name: '', mobile: ''});

    const [state, setState] = React.useState({ 
        name: '',
        email: '',
        mobile: '',
        profile_pic: null,
    });

    const { profile, isLoading } = useSelector(state => {
        return { profile : state.admin.profile,
                isLoading: state.admin.isLoading
         };
    });

    React.useEffect(() => {
        dispatch(getProfile());
        setState({...state,
            name: profile.name,
            email: profile.email,
            mobile: profile.mobile,
            profile_pic: profile.profile_pic
        })
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (profile.id !== undefined)
            setState({...state,
                name: profile.name,
                email: profile.email,
                mobile: profile.mobile,
                profile_pic: profile.profile_pic
            })
        // eslint-disable-next-line
    }, [profile]);

    const submitForm = () => {
        if (validateForm()){
            let fd = appendFormData();
            dispatch(updateProfile(fd));
        }
    }

    const validateForm = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;
        let validationDetails = {};

        validationDetails = nameValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        validationDetails = mobileValidation(fields, errors, formIsValid);
        formIsValid = setValidationFlag(validationDetails);
        return formIsValid;
    }
    
    const setValidationFlag = (validationDetails) => {
        setErrorText(validationDetails.errors);
        return validationDetails.formIsValid;
    }

    const setImage = (imageBlob) => {
        setBlobImg(imageBlob);
    }

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const appendFormData = () => {
        let formData = new FormData();
        
        if(state.name !== profile.name && state.name !== '')
            formData.append("name", state.name);

        if(state.mobile !== profile.mobile && state.mobile !=='')
            formData.append("mobile", state.mobile);
        
        if(blobImg !== null)
            formData.append("profile_pic", blobImg);
        
        return formData;
    }

    const handleClose = () => {
        setOpen(false);
    }

    const openModal = () => {
        setOpen(true);
    }

    return(        
        <Box>
            <Grid container>

                <Grid item className='card' xs={12} sm={12} md={4} lg={4} xl={4}><br/>
                    <Box className='align-center' style={{marginBottom: 20}}>
                        <Avatar alt="Remy Sharp" sx={{ width: 140, height: 140 }} src={profile.profile_pic} /><br/>
                    </Box>               
                    <Typography className='align-center' variant='h5'>{profile.name}</Typography>
                    <Typography className='align-center' variant='h6'>{profile && profile.location ? profile.location.name : null}</Typography>
                    <Typography className='align-center'>{profile.email}</Typography>
                    <Typography className='align-center'>{profile.mobile}</Typography>            
                </Grid>

                <Grid item xs={0} sm={0} md={.2} lg={.2} xl={.2}></Grid>

                <Grid item className='card' xs={12} sm={12} md={7.8} lg={7.8} xl={7.8}>
        
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <TextField
                                id="outlined-basic"
                                size='small'
                                fullWidth
                                variant="outlined"
                                style={{marginBottom: 10}}
                                name="name"
                                error={errorText.name}
                                helperText={errorText.name}
                                onChange={handleChange}
                                value={state.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel htmlFor="mob">Mobile</InputLabel>
                            <TextField
                                id="mobile"
                                size='small'
                                fullWidth
                                variant="outlined"
                                style={{marginBottom: 10}}
                                name="mobile"
                                type='number'
                                error={errorText.mobile}
                                helperText={errorText.mobile}
                                onChange={handleChange}
                                value={state.mobile}
                            />
                        </Grid>
                    </Grid>

                    <InputLabel htmlFor="mob">Email</InputLabel>
                    <OutlinedInput
                        id="enail"
                        size='small'
                        fullWidth
                        variant="outlined"
                        disabled={true}
                        style={{marginBottom: 20}}
                        name="email"
                        value={state.email}
                    />

                    <ImagePicker setImage={setImage} api_image={null}/>

                    <Box style={{marginTop: '20px'}}>
                        <Link
                            className='left-button'
                            component="button"
                            variant="body2"
                            onClick={openModal}>
                                Change Password
                        </Link>
               
                        <Button 
                            className='right-button'
                            disabled={isLoading}
                            startIcon={isLoading === true ? <CircularProgress size={20} color="inherit"/> : null}
                            onClick={submitForm}>
                            Update
                        </Button>
                    </Box> 
                </Grid>
            </Grid>

            <PasswordChange
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
            />
        </Box>
    );
}

export default Profile;